import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NotificationsFeed from "./NotificationsFeed.js"
import { setViewedNotifications } from "../FirebaseRetrieval.js"

const Notifications = (props) => {
  const [account, setAccount] = useState("");

  const history = useHistory();
  const location = useLocation();

  const provider = props.provider;

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
      } catch (err) {
        console.error(err);
      }
    }
    fetchAccount();
  }, [provider]);

  // Set notifications as being viewed for address.
  // This is actually insecure as anyone can use this endpoint to clear notification icon, but it'll work for now.
  useEffect(() => {
    if (account) {
      setViewedNotifications(account)
    }
  }, [account]);

  return (
    <>
      <NotificationsFeed provider={provider} account={account}></NotificationsFeed>
    </>
  )
}

export default Notifications;
