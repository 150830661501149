import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import HomeFeed from "./HomeFeed.js"
import { ApproveAccountDialog } from "../Dialogs.js";
import { hasCorrectApprovedAddress } from "../FirebaseRetrieval.js"

const Home = (props) => {
  const [account, setAccount] = useState("");
  const [openApprove, setOpenApprove] = React.useState(false);

  const history = useHistory();
  const location = useLocation();

  const provider = props.provider;

  const handleCloseApprove = (value) => {
    setOpenApprove(false);
  };

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
      } catch (err) {
        console.error(err);
      }
    }
    fetchAccount();
  }, [provider]);

  useEffect(() => {
    if (provider && account) {
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (object && object.value != "rejected") {
        hasCorrectApprovedAddress(account, provider, (hasCorrect) => {
          if (!hasCorrect) {
            setOpenApprove(true);
          }
        })
      }
    }
  }, [account, provider])

  return (
    <>
      <HomeFeed provider={provider} account={account}></HomeFeed>
      <ApproveAccountDialog
        open={openApprove}
        onClose={handleCloseApprove}
        account={account}
        provider={provider}
        needsCorrection={true}
      />
    </>
  )
}

export default Home;
