import { getProfileNameFromAddress } from "./ContractHelpers.js"
import firebase from "firebase";
import { getFromCache, cache, shouldUseCache } from "./Cache.js"
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

const Web3 = require('web3');
var crypto = require('crypto');

const firebaseConfig = {
  apiKey: "AIzaSyC3dZB3RnqPWP87sugynzSinjEuIe4eV2E",
  authDomain: "bitprofile-f37a0.firebaseapp.com",
  databaseURL: "https://bitprofile-f37a0-default-rtdb.firebaseio.com",
  projectId: "bitprofile-f37a0",
  storageBucket: "bitprofile-f37a0.appspot.com",
  messagingSenderId: "331295829426",
  appId: "1:331295829426:web:31460d7476272a33b648ab",
  measurementId: "G-NDML1Z9RS7"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export let connectTwitterAPI = async (provider, account, cb) => {

  var provider = new firebase.auth.TwitterAuthProvider();
  firebase.auth().signInWithPopup(provider)
    .then( async(result) => {
      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
      // You can use these server side with your app's credentials to access the Twitter API.
      const token = result.credential.accessToken;
      const secret = result.credential.secret;
      const username = result.additionalUserInfo.username;
      const uid = result.additionalUserInfo.profile.id;

      const web3 = new Web3(provider.provider);
      var signature;
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (!object || object.value == "rejected") { // use provider
        signature = await web3.eth.personal.sign(
          'Sign message to connect your address with your Twitter:' + token + ";" + secret + ";" + username + ";" + uid,
          account,
          '' // MetaMask will ignore the password argument here
        );
      }
      else {
        signature = await web3.eth.accounts.sign('Sign message to connect your address with your Twitter:' + token + ";" + secret + ";" + username + ";" + uid, object.value);
        signature = signature.signature
      }

      var messageForServer = {
          token: token,
          secret: secret,
          username: username,
          uid: uid,
          address: account,
          signature: signature,
      };
      const messageJSON = JSON.stringify(messageForServer);
      var messageBuff = new Buffer(messageJSON);
      var encodedMessage = messageBuff.toString('base64');

      // Based on deleteFake
      // This verifies and then sets the token and secret for the address. It also updates the verified twitter entree under profile.
      const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/connectTwitterAPI?params=" + encodedMessage
      const response = await fetch(url);
      const data = await response.json();

      if (data.status == "success") {
        cb()
      }

    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error:", error)
    });
}

export let hasTwitterAPIConnected = async(address, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/hasTwitterAPIConnected?address=" + address
  const response = await fetch(url);
  const resp_json = await response.json();
  cb(resp_json.isConnected == "true")
}

export let waitForApprovedAddressTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getApproveAddressTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb("success");
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForApprovedAddressTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForMintTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getMintAddressTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForMintTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForUpvoteTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getUpvoteTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForUpvoteTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForRemoveUpvoteTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getRemoveUpvoteTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForRemoveUpvoteTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForRepostTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getRepostTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForRepostTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForRemoveRepostTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getRemoveRepostTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForRemoveRepostTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForDeletePostTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getDeletePostTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForDeletePostTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForUpdateAllTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getUpdateAllTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForUpdateAllTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let getReverseENS = async (address) => {
  var cache_name = "getReverseENS" + address
  if (shouldUseCache(cache_name, 3 * 60 * 60 * 24)) {
    return getFromCache(cache_name)
  }
  else {
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getReverseENS?address=" + address
    const response = await fetch(url);
    const resp_json = await response.json();
    if (resp_json.status == "success") {
      cache(cache_name, resp_json.name)
      return resp_json.name
    }
    else {
      cache(cache_name, "")
      return null
    }
  }
}

export let getENSAvatar = async (ens_name, address) => {
  var cache_name = "getENSAvatar_" + ens_name
  if (shouldUseCache(cache_name, 3 * 60 * 60 * 24)) {
    return getFromCache(cache_name)
  }
  else {
    try {
      const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getENSAvatar?ens_name=" + ens_name + "&address=" + address
      const response = await fetch(url);
      const resp_json = await response.json();
      if (resp_json.status == "success") {
        var obj = {"contract": resp_json.contract, "tokenId": resp_json.tokenId, "img_url": resp_json.img_url}
        cache(cache_name, obj)
        return obj
      }
      else {
        cache(cache_name, "")
        return null
      }
    }
    catch {
      return null
    }
  }
}

export let waitForFollowTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFollowTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForFollowTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForUnfollowTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getUnfollowTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForUnfollowTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let waitForVerifyTransaction = async(trys, hash, cb) => {
  const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getVerifyTransaction?hash=" + hash
  const response = await fetch(url);
  const resp_json = await response.json();

  const failedUrl = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getFailedTransaction?hash=" + hash
  const failedResponse = await fetch(failedUrl);
  const failedJSON = await failedResponse.json();

  if (resp_json.status == "success") {
    if (cb) {
      cb({status: "success", "hash": hash});
    }
  } else {
    if (failedJSON.status == "success") {
      cb({status:"fail"})
    }
    else {
      var secondsToWait = 5000
      if (trys > 10) {
        secondsToWait = 1000 * trys
      }
      window.setTimeout(function () {
        waitForVerifyTransaction(trys+1, hash, cb)
      }, secondsToWait); // try again in 5 seconds
    }
  }
}

export let getVerifiedTwitterAccounts = async (cb) => {
  firebase.database().ref("profile").child("verifiedTwitter").orderByChild("followersCount").limitToLast(50).once('value').then((snapshot) => {
    var twitter_users = []
    snapshot.forEach(function(twitter_user) {
      try {
        var obj = {}
        obj["address"] = twitter_user.key
        const children = twitter_user.val()
        obj["followersCount"] = children["followersCount"]
        obj["img"] = children["img"]
        obj["name"] = children["name"]
        obj["username"] = children["username"]
        obj["img"] = children["img"]
        if (children["username"] != "nfposts_eth" && children["username"] != "BitProfilePics") {
          twitter_users.push(obj)
        }
      }
      catch {
        console.log("err in obj")
      }
    })
    cb(twitter_users)
  })
}

export let getPostsForFeed = async (address, cb) => {
  firebase.database().ref("feed").child(address).orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      if (children["poster"]) {
        obj["poster"] = children["poster"]
      }
      if (children["reposter"]) {
        obj["reposter"] = children["reposter"]
      }
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForFeed = async (address, earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("feed").child(address).orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      if (children["poster"]) {
        obj["poster"] = children["poster"]
      }
      if (children["reposter"]) {
        obj["reposter"] = children["reposter"]
      }
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForGlobal = async (cb) => {
  firebase.database().ref("globalFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForGlobal = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("globalFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForFeatured = async (cb) => {
  firebase.database().ref("featuredFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForFeatured = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("featuredFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForBAYC = async (cb) => {
  firebase.database().ref("baycFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForBAYC = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("baycFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForSkulls = async (cb) => {
  firebase.database().ref("cryptoskullsFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForSkulls = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("cryptoskullsFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForCoolCats = async (cb) => {
  firebase.database().ref("coolcatsFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForCoolCats = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("coolcatsFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForCryptoPunk = async (cb) => {
  firebase.database().ref("cryptopunkFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForCryptoPunk = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("cryptopunkFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsForENS = async (cb) => {
  firebase.database().ref("ensFeed").orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsForENS = async (earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("ensFeed").orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getTopTodayPostsForGlobal = async (cb) => {
  var today = new Date(Date.now());
  var dd = String(today.getUTCDate()).padStart(2, '0');
  var mm = String(today.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getUTCFullYear();
  const day = mm + '_' + dd + '_' + yyyy;

  firebase.database().ref("dayFeed").child(day).orderByChild("upvotesCount").limitToLast(30).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["upvotesCount"] = children["upvotesCount"]
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getTopMonthPostsForGlobal = async (cb) => {
  var today = new Date(Date.now());
  var mm = String(today.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getUTCFullYear();
  const month = mm + '_' + yyyy;

  firebase.database().ref("monthFeed").child(month).orderByChild("upvotesCount").limitToLast(30).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["upvotesCount"] = children["upvotesCount"]
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getTopYearPostsForGlobal = async (cb) => {
  var today = new Date(Date.now());
  var mm = String(today.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getUTCFullYear();

  firebase.database().ref("yearFeed").child(yyyy).orderByChild("upvotesCount").limitToLast(30).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["upvotesCount"] = children["upvotesCount"]
      obj["timestamp"] = children["timestamp"]
      obj["poster"] = children["poster"]
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getPostsOfAddress = async (address, cb) => {
  firebase.database().ref("postsCreated").child(address).orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      if (children["poster"]) {
        obj["poster"] = children["poster"]
      }
      if (children["reposter"]) {
        obj["reposter"] = children["reposter"]
      }
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextPostsOfAddress = async (address, earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("postsCreated").child(address).orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      const children = token.val()
      obj["timestamp"] = children["timestamp"]
      if (children["poster"]) {
        obj["poster"] = children["poster"]
      }
      if (children["reposter"]) {
        obj["reposter"] = children["reposter"]
      }
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getCollectedPostsOfAddress = async (address, cb) => {
  firebase.database().ref("postsCollected").child(address).orderByValue().limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      obj["timestamp"] = token.val()
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let getNextCollectedPostsOfAddress = async (address, earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("postsCollected").child(address).orderByValue().endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(token) {
      var obj = {}
      obj["key"] = token.key
      obj["timestamp"] = token.val()
      tokens.push(obj)
    })
    cb(tokens)
  })
}

export let hasNewNotification = async (address, cb) => {
  console.log(address)
  firebase.database().ref("notifications").child(address).child("hasNewNotification").once('value').then((snapshot) => {
    if (snapshot.val()) {
      cb(true)
    }
    else {
      cb(false)
    }
  })
}

export let setViewedNotifications = async (address) => {
  try {
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/setViewedNotifications?address=" + address
    const response = await fetch(url);
    const resp_json = await response.json();
  }
  catch {

  }
}

export let getNotificationsOfAddress = async (address, cb) => {
  firebase.database().ref("notifications").child(address).orderByChild("timestamp").limitToLast(10).once('value').then((snapshot) => {
    var notifications = []
    snapshot.forEach(function(notification) {
      var obj = {}
      // hasNewNotification will also be returned but ignore it because its not a notification
      if (notification.key != "hasNewNotification") {
        const children = notification.val()
        if (children["follower"]) {
          obj["follower"] = children["follower"]
        }
        if (children["timestamp"]) {
          obj["timestamp"] = children["timestamp"]
        }
        if (children["type"]) {
          obj["type"] = children["type"]
        }
        if (children["from"]) {
          obj["from"] = children["from"]
        }
        if (children["postTokenId"]) {
          obj["postTokenId"] = children["postTokenId"]
        }
        obj["key"] = notification.key
        notifications.push(obj)
      }
    })
    cb(notifications)
  })
}

export let getNextNotificationsOfAddress = async (address, earliestPostTimestamp, cb) => {
  const timestamp = parseInt(earliestPostTimestamp)
  firebase.database().ref("notifications").child(address).orderByChild("timestamp").endBefore(timestamp).limitToLast(10).once('value').then((snapshot) => {
    var notifications = []
    snapshot.forEach(function(notification) {
      var obj = {}
      if (notification.key != "hasNewNotification") {
        const children = notification.val()
        if (children["follower"]) {
          obj["follower"] = children["follower"]
        }
        if (children["timestamp"]) {
          obj["timestamp"] = children["timestamp"]
        }
        if (children["type"]) {
          obj["type"] = children["type"]
        }
        if (children["from"]) {
          obj["from"] = children["from"]
        }
        if (children["postTokenId"]) {
          obj["postTokenId"] = children["postTokenId"]
        }
         obj["key"] = notification.key
        notifications.push(obj)
      }
    })
    cb(notifications)
  })
}

export let getRepliesOfPost = async (tokenId, cb) => {
  // order by child
  firebase.database().ref("childPosts/").child(tokenId).once('value').then((snapshot) => {
    var tokens = []
    snapshot.forEach(function(tokenId) {
      tokens.push(tokenId.key)
    })
    cb(tokens)
  })
}

export let getThreadChildId = async (tokenId, cb) => {
  firebase.database().ref("threadChildPost/").child(tokenId).once('value').then((snapshot) => {
    cb (snapshot.val())
  })
}

export let getPostData = async (tokenId, cb) => {
  firebase.database().ref("posts/").child(tokenId).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    var dict = {}
    snapshot.forEach(function(entree) {
      dict[entree.key] = entree.val()
    })
    dict["tokenId"] = tokenId
    getProfileNameFromAddress(dict["poster"], (name) => {
      dict["name"] = name
      getProfileNameFromAddress(dict["owner"], (ownerName) => {
        dict["ownerName"] = ownerName
        cb(dict)
      })
    })
  })
}

export let getFollowersCount = async (address, cb) => {
  firebase.database().ref("followersCount").child(address).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb(0)
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getFollowingCount = async (address, cb) => {
  firebase.database().ref("followingCount").child(address).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb(0)
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getFollowersOfAddress = async (address, cb) => {
  firebase.database().ref("followers").child(address).orderByValue().limitToLast(20).once('value').then((snapshot) => {
    var followerAddresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      followerAddresses.push(obj)
    })
    cb(followerAddresses)
  })
}

export let getNextFollowersOfAddress = async (address, earliestFollowerTimestamp, cb) => {
  firebase.database().ref("followers").child(address).orderByValue().endBefore(earliestFollowerTimestamp).limitToLast(10).once('value').then((snapshot) => {
    var followerAddresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      followerAddresses.push(obj)
    })
    cb(followerAddresses)
  })
}

export let getFollowingOfAddress = async (address, cb) => {
  // order by child
  firebase.database().ref("following").child(address).orderByValue().limitToLast(20).once('value').then((snapshot) => {
    var followingAddresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      followingAddresses.push(obj)
    })
    cb(followingAddresses)
  })
}

export let getNextFollowingOfAddress = async (address, earliestFollowingTimestamp, cb) => {
  firebase.database().ref("following").child(address).orderByValue().endBefore(earliestFollowingTimestamp).limitToLast(10).once('value').then((snapshot) => {
    var followingAddresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      followingAddresses.push(obj)
    })
    cb(followingAddresses)
  })
}

export let getRepostersOfPost = async (tokenId, cb) => {
  // order by child
  firebase.database().ref("reposters").child(tokenId).orderByValue().limitToLast(20).once('value').then((snapshot) => {
    var addresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      addresses.push(obj)
    })
    cb(addresses)
  })
}

export let getNextRepostersOfPost = async (tokenId, earliestTimestamp, cb) => {
  firebase.database().ref("reposters").child(tokenId).orderByValue().endBefore(earliestTimestamp).limitToLast(10).once('value').then((snapshot) => {
    var addresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      addresses.push(obj)
    })
    cb(addresses)
  })
}

export let getUpvotersOfPost = async (tokenId, cb) => {
  // order by child
  firebase.database().ref("upvoters").child(tokenId).orderByValue().limitToLast(20).once('value').then((snapshot) => {
    var addresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      addresses.push(obj)
    })
    cb(addresses)
  })
}

export let getNextUpvotersOfPost = async (tokenId, earliestTimestamp, cb) => {
  firebase.database().ref("upvoters").child(tokenId).orderByValue().endBefore(earliestTimestamp).limitToLast(10).once('value').then((snapshot) => {
    var addresses = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["timestamp"] = snap.val()
      addresses.push(obj)
    })
    cb(addresses)
  })
}

export let getAddressBio = async (address, cb) => {
  firebase.database().ref("profile/bio/").child(address).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getAddressWebsite = async (address, cb) => {
  firebase.database().ref("profile/website/").child(address).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getAddressFromTwitterUsername = async (username, cb) => {
  var usernameHash = crypto.createHash('md5').update(username).digest('hex');
  firebase.database().ref("twitterUsername").child(usernameHash).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    cb(snapshot.val())
  })
}

export let getProfileNFT = async (address, cb) => {
  firebase.database().ref("chainForAvatar").child(address).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    const chainId = snapshot.val()
    firebase.database().ref("avatar/addressAvatar/").child(address).child("contract").once('value').then((contract_snapshot) => {
      if (!contract_snapshot.val()) {
        cb()
        return;
      }
      const nft_contract_addr = contract_snapshot.val()
      firebase.database().ref("avatar/addressAvatar/").child(address).child("tokenId").once('value').then((tokenId_snapshot) => {
        if (!tokenId_snapshot.val()) {
          cb()
          return;
        }

        const nft_tokenid = tokenId_snapshot.val()
        var cbDict = {};
        cbDict.nft_contract_addr = nft_contract_addr;
        cbDict.nft_tokenid = nft_tokenid;
        cbDict.chainId = chainId
        cb(cbDict)
      })
    })
  })
}

// this is called by avatar.js
export let getNFTImageURL = async (tokenHash, cb) => {
  firebase.database().ref("nftImg").child(tokenHash).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    cb(snapshot.val())
  })
}

export let getNFTChainId = async (tokenHash, cb) => {
  firebase.database().ref("nftImgChainId").child(tokenHash).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    cb(snapshot.val())
  })
}

export let getNumLikes = async (tokenId, cb) => {
  firebase.database().ref("upvotesCount").child(tokenId).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb(0)
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getNumReposts = async (tokenId, cb) => {
  firebase.database().ref("repostsCount").child(tokenId).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb(0)
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getNumReplies = async (tokenId, cb) => {
  firebase.database().ref("repliesCount").child(tokenId).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb(0)
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getTwitterUsername = async (address, cb) => {
  firebase.database().ref("profile").child("verifiedTwitter").child(address).child("username").once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb("")
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let getUseVerifiedUsername = async (address, cb) => {
  firebase.database().ref("profile").child("useSourceUsername").child(address).once('value').then((snapshot) => {
    if (!snapshot.val()) {
      cb()
      return;
    }
    cb(snapshot.val())
    return;
  })
}

export let hasLikedPost = async (address, tokenId, cb) => {
  if (!address || !tokenId) {
    cb(false)
    return
  }
  firebase.database().ref("postsUpvoted/").child(address).child(tokenId).once('value').then((snapshot) => {
    if (snapshot.val()) {
      cb(true)
    }
    else {
      cb(false)
    }
  })
}

export let hasLikedPostPending = async (address, tokenId, cb) => {
  if (!address || !tokenId) {
    cb(false)
    return
  }
  firebase.database().ref("pendingUpvote/").child(address).child(tokenId).once('value').then((snapshot) => {
    if (snapshot.val()) {
      const upvoteTime = snapshot.val()
      const currentTime = Math.floor(Date.now() / 1000)
      if (currentTime - upvoteTime < 60 * 5) {
        cb(true)
      }
      else {
        cb(false)
      }
    }
    else {
      cb(false)
    }
  })
}

export let hasRepostedPost = async (address, tokenId, cb) => {
  if (!address || !tokenId) {
    cb(false)
    return
  }
 firebase.database().ref("postsReposted/").child(address).child(tokenId).once('value').then((snapshot) => {
   if (snapshot.val()) {
     cb(true)
   }
   else {
     cb(false)
   }
 })
}

export let isFollowingAddress = async (address, account, cb) => {
  if (!address || !account) {
    cb(false)
    return
  }
 firebase.database().ref("following/").child(account).child(address).once('value').then((snapshot) => {
   if (snapshot.val()) {
     cb(true)
   }
   else {
     cb(false)
   }
 })
}

export let getTokenIdFromShortId = async (shortId, cb) => {
 firebase.database().ref("shortId/").child(shortId).once('value').then((snapshot) => {
   if (snapshot.val()) {
     cb(snapshot.val())
   }
   else {
     cb("")
   }
 })
}

export let isPostFake = async (tokenId, cb) => {
 firebase.database().ref("posts/").child(tokenId).child("dataForMint").once('value').then((snapshot) => {
   if (snapshot.val()) {
     cb(true)
   }
   else {
     cb(false)
   }
 })
}

export let getFakePostData = async (tokenId, cb) => {
 firebase.database().ref("posts/").child(tokenId).child("dataForMint").once('value').then((snapshot) => {
   if (snapshot.val()) {
     cb(snapshot.val())
   }
 })
}

export let isWhitelistedForPosting = async (address, cb) => {
 firebase.database().ref("whitelistedPosters/").child(address).once('value').then((snapshot) => {
   if (snapshot.val()) {
     cb(true)
   }
   else {
     cb(false)
   }
 })
}

export let getTopCollectors = async (cb) => {
  firebase.database().ref("postsCollectedCount").orderByValue().limitToLast(30).once('value').then((snapshot) => {
    var collectors = []
    snapshot.forEach(function(snap) {
      var obj = {}
      obj["address"] = snap.key
      obj["num_collected"] = snap.val()
      collectors.push(obj)
    })
    cb(collectors)
  })
}

export let hasCorrectApprovedAddress = async (address, provider, cb) => {
  firebase.database().ref("approvedAddress").child(address).once('value').then((snapshot) => {
    if (snapshot.val()) {
      const approved_address = snapshot.val()
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (!object || object.value == "rejected") {
        cb (false)
      }
      else {
        const web3 = new Web3(provider.provider);
        const cached_approved_address = web3.eth.accounts.privateKeyToAccount(object.value).address;
        if (cached_approved_address == undefined || cached_approved_address == null) {
          cb (false)
        }
        else {
          cb (approved_address == cached_approved_address)
        }
      }
    }
    else {
      cb (false)
    }
  })
}

export default { waitForApprovedAddressTransaction, waitForMintTransaction, getPostsOfAddress,
  getPostData, hasLikedPost, hasRepostedPost, waitForUpvoteTransaction, waitForRemoveUpvoteTransaction,
  waitForRepostTransaction, waitForRemoveRepostTransaction, waitForUpdateAllTransaction,
  getAddressBio, getAddressWebsite, getProfileNFT, getNFTImageURL,
   waitForFollowTransaction, waitForUnfollowTransaction, isFollowingAddress }














