import React, { useEffect, useState } from "react";
import { Button } from "./components";

import AppBar from '@mui/material/AppBar';
import MuiButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AccountIcon from '@mui/icons-material/Face';
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import { SearchDialog } from "./Dialogs.js"
import logo from './icon.png';
import logoWhite from './icon4.png';
import { useHistory } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

import { cache } from "./Cache.js"
import { ApproveAccountDialog } from "./Dialogs.js";
import { hasNewNotification } from "./FirebaseRetrieval.js"

function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [open, setOpen] = React.useState(false);
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);

        // Resolve the ENS name for the first account.
//        const name = await provider.lookupAddress(accounts[0]);

        // Render either the ENS name or the shortened account address.
//        if (name) {
//          setRendered(name);
//        } else {
//          setRendered(account.substring(0, 5) + "..." + account.substring(38));
//        }
        setRendered(account.substring(0, 5) + "..." + account.substring(38));
      } catch (err) {
        setAccount("");
        setRendered("");
        console.error(err);
      }
    }
    fetchAccount();
  }, [provider, account, setAccount, setRendered]);

  useEffect(() => {
    async function showApproveAddressIfNeeded() {
      handleClickOpen()
    }

    if (account) {
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (!object) {
        showApproveAddressIfNeeded();
      }
    }
  }, [account]);

  return (
    <>
      <Button style={{backgroundColor: "rgb(203,232,253)", color: "#0094ff", fontSize: "12px", fontWeight: "600", marginTop: "0px", marginRight: "0px", width: "140px"}}
        onClick={() => {
          if (!provider) {
            loadWeb3Modal();
          } else {
            localStorage.removeItem('approved_pk');
            logoutOfWeb3Modal();
          }
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {rendered === "" && "Connect Wallet"}
          {rendered !== "" && rendered}
        </div>
      </Button>
      <ApproveAccountDialog
        open={open}
        onClose={handleClose}
        account={account}
        provider={provider}
      />
    </>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
//      borderBottomWidth: "1px",
//      borderWidth: "0px",
//      borderRadius: "0px",
//      borderColor: "rgb(196 196 196)"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
//      borderBottomWidth: "1px",
//      borderWidth: "0px",
//      borderRadius: "0px",
//      borderColor: "rgb(196 196 196)"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
//      borderBottomWidth: "1px",
//      borderWidth: "0px",
//      borderRadius: "0px",
//      borderColor: "rgb(196 196 196)"
    },
//    "& .MuiOutlinedInput-input": {
//      color: "green"
//    },
//    "&:hover .MuiOutlinedInput-input": {
//      color: "red"
//    },
//    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
//      color: "purple"
//    },
//    "& .MuiInputLabel-outlined": {
//      color: "green"
//    },
//    "&:hover .MuiInputLabel-outlined": {
//      color: "red"
//    },
//    "& .MuiInputLabel-outlined.Mui-focused": {
//      color: "purple"
//    }
  }
});

export function TopAppBar({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchInput, setSearchInput] = React.useState("")
  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const [hasNewNotif, setHasNewNotif] = useState(false)
  const [account, setAccount] = useState()

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false)
  }

  const openSearch = () => {
    setOpenSearchDialog(true)
  }

  const classes = useStyles();

  let history = useHistory();

  function clearHomeFeedCache() {
    cache("home_feed_pos", 0)
    localStorage.removeItem("feedPosts")
    localStorage.removeItem("globalPosts")
  }

  const goToProfile = () => {
    if (searchInput != "") {
      // If an invalid profile is opened, it won't reload properly. This needs to be fixed. So for now just force reload page.
      setSearchInput("")
      window.location.href = "/" + searchInput
    }
  }

  const openOwnProfile = () => {
    clearHomeFeedCache()
//    history.push('/profile')
    window.location.href = "/profile"
  }

  const openNotifications = () => {
    clearHomeFeedCache()
//    history.push('/notifications')
    window.location.href = "/notifications"
  }

  const openHomeFresh = () => {
    clearHomeFeedCache()
//    history.push('/')
    window.location.href = "/new"
  }

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
      } catch (err) {
      }
    }
    fetchAccount();
  }, [provider]);

  useEffect(() => {
    if (account) {
      hasNewNotification(account, (hasNew) => {
        setHasNewNotif(hasNew)
      })
    }
  }, [account]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "#fff", boxShadow: "none",
          borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#eee" }}>
          <Toolbar>
           <Box sx={{ flexGrow: 0 }} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' }, marginTop: "5px" }}
            >
              <span onClick={openHomeFresh} style={{color: "#000", cursor: "pointer"}} className="navbar-brand"><img style={{width: "30px", height: "30px", marginRight: "10px"}} src={logo} /><strong>NFPosts</strong></span>
            </Typography>

            <Box sx={{ flexGrow: 0.95 }} />

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <MuiButton variant="text" onClick={openNotifications} 
                sx={{ 
                  marginTop: "auto", 
                  marginLeft: "5px",
                  alignItems: "center", 
                  textTransform: "none", 
                  color: window.location.href.includes("/notifications") ? "#1876d1" : "#444",
                  borderBottomStyle: window.location.href.includes("/notifications") ? "solid": "none",
                  borderBottomWidth: "2px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomColor: window.location.href.includes("/notifications") ? "#1876d1" : "#444",
                  ':hover': {
                    bgcolor: '#f7f7f7',
                  }
                  }} 
                >
                { (hasNewNotif && !window.location.pathname.includes("notifications")) ?
                    <NotificationsActiveIcon style={{ color: window.location.href.includes("/notifications") ? "#1876d1" : "#444" }} />
                  :
                    <NotificationsIcon style={{ color: window.location.href.includes("/notifications") ? "#1876d1" : "#444" }} />
                }
                <span style={{ paddingLeft: "10px" }}>Notifications</span>
              </MuiButton>    
              <MuiButton variant="text" onClick={openOwnProfile} 
                sx={{ 
                  marginTop: "auto", 
                  marginLeft: "5px",
                  alignItems: "center", 
                  textTransform: "none", 
                  color: window.location.href.includes("/profile") ? "#1876d1" : "#444",
                  borderBottomStyle: window.location.href.includes("/profile") ? "solid": "none",
                  borderBottomWidth: "2px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomColor: window.location.href.includes("/profile") ? "#1876d1" : "#444",
                  ':hover': {
                    bgcolor: '#f7f7f7',
                  }
                  }} 
                >
                <AccountIcon style={{color: window.location.href.includes("/profile") ? "#1876d1" : "#444",}} />
                <span style={{ paddingLeft: "10px" }}>Profile</span>
              </MuiButton>
            </Box>

            <Box sx={{ flexGrow: 0.05 }} />

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <SearchIcon onClick={openSearch} sx={{color: "#444", marginTop: "0px", marginLeft: "0px", cursor: "pointer"}} />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <TextField
                className={classes.root}
                variant="outlined"
                placeholder="Search"
                value={searchInput}
                onInput={e => setSearchInput(e.target.value)}
                InputProps={{
                  style: { borderColor: "blue"},
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={goToProfile}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    goToProfile()
                  }
                }}
              />
            </Box>
            <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
          </Toolbar>
        </AppBar>
      </Box>
      <SearchDialog
        open={openSearchDialog}
        onClose={handleCloseSearchDialog}
      />
    </>
  );
}

export function TopAppBarLanding({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [searchInput, setSearchInput] = React.useState("")
  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const [hasNewNotif, setHasNewNotif] = useState(false)
  const [account, setAccount] = useState()

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false)
  }

  const openSearch = () => {
    setOpenSearchDialog(true)
  }

  const classes = useStyles();

  const openHomeFresh = () => {
    window.location.href = "/"
  }

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
      } catch (err) {
      }
    }
    fetchAccount();
  }, [provider]);

  useEffect(() => {
    if (account) {
      hasNewNotification(account, (hasNew) => {
        setHasNewNotif(hasNew)
      })
    }
  }, [account]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ background: "linear-gradient(270deg, rgb(27 1 57) 0%, rgb(75 0 122) 100%)", boxShadow: "none",
          borderBottomWidth: "0px", borderBottomStyle: "solid", borderBottomColor: "#52009fbd" }}>
          <Toolbar>
           <Box sx={{ flexGrow: 0 }} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' }, marginTop: "5px" }}
            >
              <span onClick={openHomeFresh} style={{color: "#000", cursor: "pointer"}} className="navbar-brand"><img style={{width: "30px", height: "30px", marginRight: "10px"}} src={logoWhite} /><strong style={{color: "#fff"}}>NFPosts</strong></span>
            </Typography>
            <Box sx={{ flexGrow: 0.95 }} />
            <MuiButton variant="text" onClick={() => window.location.href="/"}
              sx={{ 
                marginLeft: "5px",
                alignItems: "center", 
                textTransform: "none",
                color: !(window.location.href.includes("/reserve") || window.location.href.includes("/join"))  ? "#fff" : "#ffffff7a",
                borderBottomStyle: !(window.location.href.includes("/reserve") || window.location.href.includes("/join"))  ? "solid": "none",
                borderBottomWidth: "2px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottomColor: !(window.location.href.includes("/reserve") || window.location.href.includes("/join"))  ? "#fff" : "#ffffff7a",
                ':hover': {
                  bgcolor: '#f7f7f7',
                  color: "#444"
                }
                }} 
              >
              <HomeIcon/>
              <span style={{ paddingLeft: "10px" }}>Home</span>
            </MuiButton>
            <MuiButton variant="text" onClick={() => window.location.href="/reserve"}
              sx={{ 
                marginLeft: "5px",
                alignItems: "center", 
                textTransform: "none",
                color: (window.location.href.includes("/reserve") || window.location.href.includes("/join"))  ? "#fff" : "#ffffff7a",
                borderBottomStyle: (window.location.href.includes("/reserve") || window.location.href.includes("/join"))  ? "solid": "none",
                borderBottomWidth: "2px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottomColor: (window.location.href.includes("/reserve") || window.location.href.includes("/join")) ? "#fff" : "#ffffff7a",
                ':hover': {
                  bgcolor: '#f7f7f7',
                  color: "#444"
                }
                }} 
              >
              <AccountIcon/>
              <span style={{ paddingLeft: "10px" }}>Reserve</span>
            </MuiButton>
            <Box sx={{ flexGrow: 0.05 }} />
          </Toolbar>
        </AppBar>
      </Box>
      <SearchDialog
        open={openSearchDialog}
        onClose={handleCloseSearchDialog}
      />
    </>
  );
}