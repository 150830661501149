import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Post from "./Post.js"
import { getPostsOfAddress, getPostData, waitForMintTransaction,
          getRepliesOfPost, getThreadChildId, hasCorrectApprovedAddress } from "../FirebaseRetrieval.js"
import { ApproveAccountDialog } from "../Dialogs.js";

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';

const scrollToRef = (ref) => {
  document.getElementById("list").scroll(0,ref.current.offsetTop - 70)
}

const PostPage = (props) => {

  let { postTokenId } = useParams();
  const [mainPost, setMainPost] = useState();
  const [mainPostId, setMainPostId] = useState();
  const [parentPosts, setParentPosts] = useState([]);
  const [replyPosts, setReplyPosts] = useState([]);
  const [newReplyPost, setNewReplyPost] = useState();
  const [postDeleted, setPostDeleted] = useState();
  const [account, setAccount] = useState()
  const [provider, setProvider] = useState()
  const [autoOpenReply, setAutoOpenReply] = useState(false)
  const [openApprove, setOpenApprove] = React.useState(false);

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  const handleCloseApprove = (value) => {
    setOpenApprove(false);
  };

  useEffect(() => {
    if (postTokenId) {
      getPostData(postTokenId, (postDict) => {
        if (postDict) {
          setMainPost(postDict)
          setMainPostId(postDict.tokenId);
        }
      })
    }
  }, [postTokenId]);

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
        setAutoOpenReply(false);
      } catch (err) {
        console.error(err);
      }
    }
    fetchAccount();
  }, [provider]);

  useEffect(() => {
    setProvider(props.provider)
  }, [props.provider]);

  useEffect(() => {
    if (provider && account) {
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (object && object.value != "rejected") {
        hasCorrectApprovedAddress(account, provider, (hasCorrect) => {
          if (!hasCorrect) {
            setOpenApprove(true);
          }
        })
      }
    }
  }, [account, provider])

  function openReply () {
    setAutoOpenReply(true)
  }

  useEffect(() => {
    async function fetchPostsDataFirebase(posts, cb) {
      if (posts.length == 0) {
        cb([])
      }
      else if (posts.length == 1) {
        try {
          getPostData(posts[0], (postDict) => {
            cb([postDict])
          })
        }
        catch {
          cb([])
        }
      }
      else {
        // get post data for latest post
        try {
          getPostData(posts[0], (postDict) => {
            var poppedPosts = [...posts];
            poppedPosts.shift();
            fetchPostsDataFirebase(poppedPosts, (postsArr) => {
              postsArr.push(postDict)
              cb(postsArr)
            })
          })
        }
        catch {
          var poppedPosts = [...posts];
          poppedPosts.shift();
          fetchPostsDataFirebase(poppedPosts, (postsArr) => {
            cb(postsArr)
          })
        }
      }
    }

    async function fetchParentPosts(tokenId, cb) {
      getPostData(tokenId, (postDict) => {
        if (postDict) {
          const parentPostId = postDict["parentPostId"]
          if (parentPostId == 0 || parentPostId == "0") {
            cb ([postDict])
          }
          else {
            fetchParentPosts(parentPostId, (parentArr) => {
              parentArr.push(postDict)
              cb(parentArr)
            })
          }
        }
      })
    }

    async function fetchThreadChildren(tokenId, cb) {
      getThreadChildId(tokenId, (childId) => {
        if (childId) {
          getPostData(tokenId, (postDict) => {
            fetchThreadChildren(childId, (childrenArr) => {
              childrenArr.unshift(postDict);
              cb(childrenArr)
            })
          })
        }
        else { // base case
          getPostData(tokenId, (postDict) => {
            cb([postDict])
          })
        }
      })
    }

    // also need to implement reposts
    async function fetchThread(postTokenId) {
      fetchParentPosts(postTokenId, (parents) => {
        parents.pop()
        setParentPosts(parents)
        executeScroll()
      })

      var allReplies = []
      var childrenIdSet = new Set()
      fetchThreadChildren(postTokenId, (threadChildren) => {
        console.log(threadChildren)
        threadChildren.shift()
        for (var i=0; i<threadChildren.length; i++) {
          childrenIdSet.add(threadChildren[i].tokenId)
          allReplies.push(threadChildren[i])
        }

        getRepliesOfPost(postTokenId, (posts) => {
          fetchPostsDataFirebase(posts, (postsArr) => {
            postsArr.sort((a, b) => { // sort postsArr by time
                return b.postTimestamp - a.postTimestamp;
            });
            for (var i=0; i<postsArr.length; i++) {
              if (!childrenIdSet.has(postsArr[i].tokenId)) {
                allReplies.push(postsArr[i])
              }
            }
            setReplyPosts(allReplies)
          })
        })
      })
    }
    if (postDeleted && postDeleted == postTokenId) {
      window.location.href = "/" + mainPost.poster
      return;
    }
    if (postTokenId) {
      setReplyPosts([])
      setParentPosts([])
      document.getElementById("list").scroll(0,0)
      fetchThread(postTokenId);
    }
  }, [postTokenId, newReplyPost, postDeleted]);

  return (
    <>
      <div id="list" style={{marginTop: "5px", height: "90vh", overflowY: "auto"}}>
        <Paper square elevation={0}  sx={{ pb: '50px', width: 550, maxWidth: "100%", margin: 'auto'}}>
          <List>
            {parentPosts.map(({ tokenId, poster, name, ownerName, owner, content, postTimestamp, chain }) => (
              <ListItem key={tokenId}>
                 <Post style={{ marginLeft: "10px" }} setPostDeleted={setPostDeleted} setNewReplyPost={setNewReplyPost} provider={props.provider} account={account} tokenId={tokenId} poster={poster} name={name} owner={owner} ownerName={ownerName} content={content} postTimestamp={postTimestamp} chain={chain} />
              </ListItem>
            ))}
            <ListItem ref={myRef}>
              <Paper elevation={0}>
                <React.Fragment key="MainPost">
                  <Post borderColor={"rgba(25, 118, 210, 0.5)"} isMainPost={true} setPostDeleted={setPostDeleted} autoOpenReply={autoOpenReply} setAutoOpenReply={setAutoOpenReply} setNewReplyPost={setNewReplyPost} provider={props.provider} account={account} {...mainPost} />
                </React.Fragment>
                <React.Fragment key="ReplyButton">
                  <Button onClick={() => openReply()} style={{ width: "100%", borderRadius: "5px", marginTop: "10px", padding: "10px", textTransform: 'none' }} variant="outlined">Reply</Button>
                </React.Fragment>
              </Paper>
            </ListItem>
            {replyPosts.map(({ tokenId, poster, name, ownerName, owner, content, postTimestamp, chain }) => (
              <ListItem key={tokenId}>
                <Post style={{ marginLeft: "10px" }} setPostDeleted={setPostDeleted} setNewReplyPost={setNewReplyPost} provider={props.provider} account={account} tokenId={tokenId} poster={poster} name={name} owner={owner} ownerName={ownerName} content={content} postTimestamp={postTimestamp} chain={chain} />
              </ListItem>
            ))}
          <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
          </List>
        </Paper>
      </div>
      <ApproveAccountDialog
        open={openApprove}
        onClose={handleCloseApprove}
        account={account}
        provider={provider}
        needsCorrection={true}
      />
    </>
  );
}

export default PostPage