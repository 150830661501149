import React, { useEffect, useState } from "react";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { pinFileToIPFS, pinImageToIPFS } from '../Utils/ipfs.js';
import { DispatchGroup } from "../Utils/DispatchGroup.js";
import { LoadingTransactionDialog, SuccessDialog, NftToPostDialog, PostToTwitterDialog, ConnectAPITwitterDialog } from "../Dialogs.js";
import {  getPostContract,
          waitForReceipt,
          getProfileNameFromAddress,
          post_contract_address,
          getPostNonce,
          createPost,
          getImageFromNFTContract,
          getImageFromNFT,
          blockExplorerTx,
          switchToPolygonTestnet
          } from "../ContractHelpers.js"
import { getImgFromSVG, toDataURL, svgToPng, roundCorners, compressImage } from "../Utils/CanvasUtils.js"
import { waitForMintTransaction, isWhitelistedForPosting, hasTwitterAPIConnected } from "../FirebaseRetrieval.js"
import { formatMintTransaction } from "../ManualTransactions.js"
import { getFromCache, cache, shouldUseCache } from "../Cache.js"

import { parseGIF, decompressFrames } from 'gifuct-js'

const fs = require('fs').promises;
const BN = require('bn.js');
const sigUtil = require('eth-sig-util');
const ethUtil = require('ethereumjs-util');
const crypto = require('crypto')
const ethers = require('ethers');
const Web3 = require('web3');

function ImagePreview({previewImageBlob, handleClosePhoto}) {
  return (
    <>
      <div style={{display: "flex", justifyContent: "center"}}>
        <img src={previewImageBlob} style={{width: "400px", marginTop: "25px", maxWidth: "90%", maxHeight: "170px", objectFit: "contain", backgroundColor: "transparent"}} />
      </div>
      <div style={{display: "flex", justifyContent: "center"}}>
        <IconButton sx={{color: (theme) => theme.palette.grey[500], marginTop: "10px", marginLeft: "5px"}} onClick={handleClosePhoto}>
          <CloseIcon />
        </IconButton>
      </div>
    </>
  );
}

function CreatePost({ account, provider, parentPostId, setNewPost, setReplyFinished, marginLeft }) {
  const web3 = new Web3(provider);
  const verifyingContract = post_contract_address

  const [mintInput, setMintInput] = useState();
  const [dialogTransactionLink, setDialogTransactionLink] = useState();
  const [openLoadingTransaction, setOpenLoadingTransaction] = useState(false);
  const [openSuccessMintTransaction, setOpenSuccessMintTransaction] = useState(false)
  const [openNFTToPostDialog, setOpenNFTToPostDialog] = useState(false)
  const [openPostToTwitter, setOpenPostToTwitter] = useState(false)
  const [openConnectTwitterAPI, setOpenConnectTwitterAPI] = useState(false)
  const [externalNFTImg, setExternalNFTImg] = useState()

  const [imgValue, setImgValue] = useState("")
  const [imageBlob, setImageBlob] = useState("")
  const [previewImageBlob, setPreviewImageBlob] = useState("")
  const [preppingTransaction, setPreppingTransaction] = useState(false)

  const [postContent, setPostContent] = useState("")
  const [postTokenId, setPostTokenId] = useState("")

  const [useNFTImage, setUseNFTImage] = useState(false)

  function hashed_tags(tags) {
    var arr = []
    for (var i=0; i< tags.length; i++) {
      arr.push(web3.utils.keccak256(tags[i]))
    }
    return web3.utils.soliditySha3(...arr)
  }

  useEffect(() => {
    if (externalNFTImg) {
      setPreviewImageBlob(externalNFTImg.img)
      setUseNFTImage(true)
    }
  }, [externalNFTImg]);


  async function postNFT() {
    const tags = ["Post"];

    var content = "";
    if (mintInput) { // mintInput is undefined if no content
      content = mintInput;
    }

    const random = crypto.randomBytes(256)
    const seed = web3.utils.soliditySha3(random);

    setPreppingTransaction(true)

    var metadataURI = ""
    const chainId = 4;

    var tokenId;

    if (imageBlob && !useNFTImage) {
      const img_ipfs_hash = await pinImageToIPFS(imageBlob)

      const baseTokenIdHash = web3.utils.soliditySha3(content.replace(/\n/g, '<br/>') + ";IpfsImageHash:" + img_ipfs_hash, account, seed).toString();
      const bytes32str = baseTokenIdHash.replace(/^0x/, '');
      tokenId = new BN(bytes32str, 16)
      const d = new BN('1000000000000000000000000000000000000000000000000000000000', 10);
      tokenId = tokenId.div(d)
      tokenId = tokenId.toString()

      var dict = new Object();
      dict["name"] = "BitProfile Post. Id: " + tokenId
      if (content != "") {
        dict["description"] = "View at BitProfile, a web3 social platform. https://nonfungibleposts.com/post/" + tokenId + " | " + content
      }
      else {
        dict["description"] = "View at BitProfile, a web3 social platform. https://nonfungibleposts.com/post/" + tokenId
      }
      dict["external_url"] = "https://nonfungibleposts.com/post/" + tokenId
      dict["image"] = "ipfs://" + img_ipfs_hash
      const tokenJSON = JSON.stringify(dict)
      const token_ipfs_hash = await pinFileToIPFS(tokenJSON);
      metadataURI = "ipfs://" + token_ipfs_hash;

      content += ";IpfsImageHash:" + img_ipfs_hash

      // access image and metadata to get it more in network
      fetch("https://ipfs.io/ipfs/" + img_ipfs_hash)
      fetch("https://ipfs.io/ipfs/" + token_ipfs_hash)
    }
    else if (useNFTImage) {
      const baseTokenIdHash = web3.utils.soliditySha3(content.replace(/\n/g, '<br/>') + ";PostNFT:" + externalNFTImg.nft_addr + "," + externalNFTImg.nft_id, account, seed).toString();
      const bytes32str = baseTokenIdHash.replace(/^0x/, '');
      tokenId = new BN(bytes32str, 16)
      const d = new BN('1000000000000000000000000000000000000000000000000000000000', 10);
      tokenId = tokenId.div(d)
      tokenId = tokenId.toString()

      const roundedCornersImg = await roundCorners(externalNFTImg.img);

      var svg_img_ipfs_hash = ""
      var svg = '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 550 550"> <defs> <linearGradient id="Gradient1"> <stop class="stop1" offset="0%"/> <stop class="stop2" offset="50%"/> <stop class="stop3" offset="100%"/> </linearGradient> <linearGradient id="Gradient2" x1="" x2="1" y1="0" y2="1"> <stop offset="0%" stop-color="#833ab4"/> <stop offset="100%" stop-color="#1d85fd"/> </linearGradient> <style type="text/css"><![CDATA[ #rect1 { fill: url(#Gradient1); } .stop1 { stop-color: red; } .stop2 { stop-color: black; stop-opacity: 0; } .stop3 { stop-color: blue; } ]]></style></defs> <rect width="100%" height="100%" fill="url(#Gradient2)" /> <rect x="5%" y="5%" width="90%" height="85%" style="fill:#fff" rx="15" /> <text x="520" y="530" fill="#fff" text-anchor="end" font-size="1em"> View this post at bitprofile.com/post/';
      svg += tokenId;
      svg += '</text><foreignObject class="node" x="50" y="50" width="460" height="110" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">';

      if (parentPostId != 0) {
        svg += '<div xmlns="http://www.w3.org/1999/xhtml" style="font-family: avenir; font-weight: bold; font-size: 14px; color: lightgrey; text-align: left; margin-bottom: 5px">Reply to bitprofile.com/post/';
        svg += parentPostId;
        svg += '</div>'
      }

      svg +=  '<div xmlns="http://www.w3.org/1999/xhtml" style="font-family: avenir; font-weight: bold; font-size: 25px; text-align: left;">'
      svg += content;
      svg += '</div></foreignObject>'

      const imgDataUrl = await toDataURL(roundedCornersImg)

      svg += '<image clip-path="url(#clip)" href="'
      svg += imgDataUrl
      svg += '" x="50" y="175" height="300" width="450" preserveAspectRatio="xMidYMin"/></svg>';

      const pngImg = await svgToPng(svg);
      svg_img_ipfs_hash = await pinImageToIPFS(pngImg)

      var dict = new Object();
      dict["name"] = "BitProfile Post. Id: " + tokenId
      if (content != "") {
        dict["description"] = "View at BitProfile, a web3 social platform. https://nonfungibleposts.com/post/" + tokenId + " | " + content
      }
      else {
        dict["description"] = "View at BitProfile, a web3 social platform. https://nonfungibleposts.com/post/" + tokenId
      }
      dict["external_url"] = "https://nonfungibleposts.com/post/" + tokenId
      dict["image"] = "ipfs://" + svg_img_ipfs_hash
      const tokenJSON = JSON.stringify(dict)
      const token_ipfs_hash = await pinFileToIPFS(tokenJSON);
      metadataURI = "ipfs://" + token_ipfs_hash;

      // Access image and metadata to get it more in network
      // Otherwise opensea might not retrieve it well.
      fetch("https://ipfs.io/ipfs/" + svg_img_ipfs_hash)
      fetch("https://ipfs.io/ipfs/" + token_ipfs_hash)

      content += ";PostNFT:" + externalNFTImg.nft_addr + "," + externalNFTImg.nft_id
    }
    else {
      const baseTokenIdHash = web3.utils.soliditySha3(content.replace(/\n/g, '<br/>'), account, seed).toString();
      const bytes32str = baseTokenIdHash.replace(/^0x/, '');
      tokenId = new BN(bytes32str, 16)
      const d = new BN('1000000000000000000000000000000000000000000000000000000000', 10);
      tokenId = tokenId.div(d)
      tokenId = tokenId.toString()
    }

    var parentToken = 0
    if (parentPostId) {
      parentToken = parentPostId
    }

    setPostContent(content)
    setPostTokenId(tokenId)

    content = content.replace(/\n/g, '<br/>')

    var approved_object = JSON.parse(localStorage.getItem("approved_pk"));
    if (!approved_object || approved_object.value == "rejected") { // use provider so switch
      const switched = await switchToPolygonTestnet();
      if (!switched) {
        setImageBlob()
        setPreviewImageBlob()
        setImgValue("")
        setExternalNFTImg("")
        setMintInput("")
        setUseNFTImage(false)
        setPreppingTransaction(false)
        alert("Please add the polygon network before sending transaction. If your wallet doesn't support this, try using metamask.")
        return;
      }
    }
    const encodedMessage = await createPost(provider, account, parentToken, content, metadataURI, seed, tokenId)

    // If user isn't whitelisted for posting, then do fake mint
    isWhitelistedForPosting(account, async (isWhitelisted) => {

      var fakeMint = !isWhitelisted

      // Delete this after
      isWhitelisted = true
      fakeMint = false

      var url;
      if (fakeMint) {
        url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/fakeMint?params=" + encodedMessage;
      }
      else {
        url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/mintTransactionRelay?params=" + encodedMessage
      }
      const response = await fetch(url);
      const data = await response.json();

      if (data.status == "success") {
        if (fakeMint) {

          setImageBlob()
          setPreviewImageBlob()
          setImgValue("")
          setExternalNFTImg("")
          setMintInput("")
          setUseNFTImage(false)

          localStorage.removeItem("home_feed_pos")
          localStorage.removeItem("feedPosts")
          localStorage.removeItem("globalPosts")

          if (setNewPost) {
            setTimeout(() => {
              setNewPost(tokenId)
              setPreppingTransaction(false)
            }, 5000)
          }

          if (setReplyFinished) {
            setTimeout(() => {
              setReplyFinished(true)
            }, 5000)
          }
  
          setOpenPostToTwitter(true)

//           hasTwitterAPIConnected(account, (isConnected) => {
//             if (isConnected) {
// //              setTimeout(() => {
// //                window.location.reload()
// //              }, 1000)
//             }
//             else {
//               var cache_name = "connect_twitter"
//               var num_asked = 0
//               if (shouldUseCache(cache_name, 5000 * 60 * 60 * 24)) {
//                 num_asked = getFromCache(cache_name)
//               }

//               setTimeout(() => {
//                 cache(cache_name, num_asked+1)
//                 if (num_asked == 1) {
//                   setOpenConnectTwitterAPI(true)
//                 }
//                 else {
//                   setOpenPostToTwitter(true)
// //                  window.location.reload()
//                 }
//               }, 11000)
//             }
//           })
        }
        else {
          const transaction = data.hash;
          console.log(transaction)
          setDialogTransactionLink(blockExplorerTx + transaction)
          setOpenLoadingTransaction(true)
          setPreppingTransaction(false)

          setImageBlob()
          setPreviewImageBlob()
          setImgValue("")
          setExternalNFTImg("")
          setMintInput("")

          waitForMintTransaction(0, transaction, function (receipt) {
            if (receipt.status == "fail") {
              // show fail popup
              handleCloseLoadingTransaction()
              var runManual = window.confirm("Transaction failed. Send transaction manually?");
              if (runManual) {
                submitManualTransaction(encodedMessage)
              }
            }
            else if (receipt.status == "success") {
              localStorage.removeItem("home_feed_pos")
              localStorage.removeItem("feedPosts")
              localStorage.removeItem("globalPosts")

              if (setNewPost) {
                setNewPost(receipt.hash)
              }

              setOpenPostToTwitter(true)
              handleCloseLoadingTransaction()
              setDialogTransactionLink(blockExplorerTx + transaction)
              setOpenSuccessMintTransaction(true)

//               hasTwitterAPIConnected(account, (isConnected) => {
//                 if (isConnected) {
//                   // Inside of fakeMint and addMintPostTransaction tweet out

//                   setTimeout(() => {
//                     window.location.reload()
//                   }, 1000)

//                   handleCloseLoadingTransaction()
//                   setDialogTransactionLink(blockExplorerTx + transaction)
//                   setOpenSuccessMintTransaction(true)
//                 }
//                 else {
//                   var cache_name = "connect_twitter"
//                   var num_asked = 0
//                   if (shouldUseCache(cache_name, 5000 * 60 * 60 * 24)) {
//                     num_asked = getFromCache(cache_name)
//                   }

//                   setTimeout(() => {
//                     if (num_asked == 0) {
//                       setOpenConnectTwitterAPI(true)
//                     }
//                     else {
//                       setOpenPostToTwitter(true)
// //                      window.location.reload()
//                     }
//                   }, 1000)

//                   handleCloseLoadingTransaction()
//                   setDialogTransactionLink(blockExplorerTx + transaction)
//                   setOpenSuccessMintTransaction(true)
//                 }
//               })
            }
            if (setReplyFinished) {
              setReplyFinished(true)
            }
          });
        }
      }
      else if (data.status == "transactionLimit") {
        setImageBlob()
        setPreviewImageBlob()
        setImgValue("")
        setExternalNFTImg("")
        setMintInput("")
        setUseNFTImage(false)
        setPreppingTransaction(false)
        var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
        if (runManual) {
          submitManualTransaction(encodedMessage)
        }
      }
      else {
        setImageBlob()
        setPreviewImageBlob()
        setImgValue("")
        setUseNFTImage(false)
        setExternalNFTImg("")
        setMintInput("")
        setPreppingTransaction(false)
        var runManual = window.confirm("Posting failed. Send transaction manually?");
        if (runManual) {
          submitManualTransaction(encodedMessage)
        }
      }
    })
  }

  async function post() {
    try {
      await postNFT()
    }
    catch {
      alert("Posting failed. Please try again.")
      setImageBlob()
      setPreviewImageBlob()
      setImgValue("")
      setExternalNFTImg("")
      setUseNFTImage(false)
      setMintInput("")
      setPreppingTransaction(false)
    }
  }

  const submitManualTransaction = async (encodedMessage) => {
    const switched = await switchToPolygonTestnet();
    if (!switched) {
      alert("Please add the polygon network before sending transaction. If your wallet doesn't support this, try again with metamask")
      return;
    }
    formatMintTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setDialogTransactionLink(blockExplorerTx + hash)
        setOpenLoadingTransaction(true)
      }).on('receipt', function(receipt){
        localStorage.removeItem("home_feed_pos")
        localStorage.removeItem("feedPosts")
        localStorage.removeItem("globalPosts")

        if (setNewPost) {
          setNewPost(receipt.hash)
        }
        if (setReplyFinished) {
          setReplyFinished(true)
        }

        setOpenPostToTwitter(true)
        handleCloseLoadingTransaction()
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenSuccessMintTransaction(true)

//         hasTwitterAPIConnected(account, (isConnected) => {
//           if (isConnected) {
//             // Inside of fakeMint and addMintPostTransaction tweet out

//             setTimeout(() => {
//               window.location.reload()
//             }, 1000)

//             handleCloseLoadingTransaction()
//             setDialogTransactionLink(blockExplorerTx + transactionHash)
//             setOpenSuccessMintTransaction(true)
//           }
//           else {
//             var cache_name = "connect_twitter"
//             var num_asked = 0
//             if (shouldUseCache(cache_name, 5000 * 60 * 60 * 24)) {
//               num_asked = getFromCache(cache_name)
//             }

//             setTimeout(() => {
//               if (num_asked == 0) {
//                 setOpenConnectTwitterAPI(true)
//               }
//               else {
//                 setOpenPostToTwitter(true)
// //                window.location.reload()
//               }
//             }, 1000)

//             handleCloseLoadingTransaction()
//             setDialogTransactionLink(blockExplorerTx + transactionHash)
//             setOpenSuccessMintTransaction(true)
//           }
//         })

      }).on('error', (err) => {
        alert("Failed to mint Post. Please try again. Error: ", err)
        handleCloseLoadingTransaction()
      });
    })
  };

  const uploadFile = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function(event) {
      fetch(event.target.result)
      .then(res => {
        res.blob().then((blob) => {
          console.log(blob.size)
          if (blob.size > 5000000){ // 5mb
            alert("Max image size is 5mb")
            return;
          }
          const blobUrl = URL.createObjectURL(blob);
          setUseNFTImage(false)

          fetch(event.target.result)
          .then(resp => resp.arrayBuffer())
          .then(buff => parseGIF(buff))
          .then(gif => {
            const gifArr = decompressFrames(gif, true)
            var isGif = false
            if (gifArr.length > 0) {
              isGif = true
            }

            if (isGif) {
              setImageBlob(blobUrl)
              setPreviewImageBlob(blobUrl)
            }
            else {
              compressImage(blobUrl).then((compressedImage) => {
                setImageBlob(compressedImage)
                setPreviewImageBlob(compressedImage)
              })
            }
          });
        })
      })
    };
    reader.readAsDataURL(file);
  }

//  let tweet = async(tokenId, contentText) => {
//    var content = contentText
//    if (content) {
//      if (content.includes(";PostNFT:")) {
//        var postContent = content.split(";PostNFT:")[0]
//        postContent = postContent.replace(/\n/g, "%0A")
//
//        const nft_info = content.split(";PostNFT:")[1]
//        const nft_address = nft_info.split(",")[0]
//        const nft_id = nft_info.split(",")[1]
//        getImageFromNFTContract(nft_address, nft_id, async(img) => {
//          var text = "https://twitter.com/intent/tweet?text=" + postContent
//          text += "%0A%0ANFT: bitprofile.io/post/" + tokenId
//
//          var messageForServer = {
//            imageURL: img
//          }
//          const messageJSON = JSON.stringify(messageForServer);
//          var messageBuff = new Buffer(messageJSON);
//          var encodedMessage = messageBuff.toString('base64');
//          const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getTwitterImage?params=" + encodedMessage
//          const response = await fetch(url);
//          const data = await response.json();
//
//          if (data.status == "success") {
//            const respData = data.data
//            const id = respData["id"].toString()
//            const twitterPic = respData["entities"]["media"][0]["display_url"]
//            text += "%0A%0A&url=" + encodeURI(twitterPic)
//            window.open(text, '_blank')
//          }
//          else {
//            // This gets the image from OpenSea or web3 and NOT from BitProfile.
//            try {
//              getImageFromNFT(nft_address, nft_id, false, (imgUrl) => {
//                text += "%0A%0A[Message for tweeter... upload this image manually: " + imgUrl + "]"
//                window.open(text, '_blank')
//              })
//            }
//            catch {
//              getImageFromNFT(nft_address, nft_id, true, (imgUrl) => {
//                text += "%0A%0A[Message for tweeter... upload this image manually: " + imgUrl + "]"
//                window.open(text, '_blank')
//              })
//            }
//          }
//        })
//      }
//      else if (content.includes(";IpfsImageHash:")) {
//        var postContent = content.split(";IpfsImageHash:")[0]
//        postContent = postContent.replace(/\n/g, "%0A")
//
//        const img = "https://ipfs.io/ipfs/" + content.split(";IpfsImageHash:")[1]
//
//        var text = "https://twitter.com/intent/tweet?text=" + postContent
//        text += "%0A%0ANFT: bitprofile.io/post/" + tokenId
//
//        var messageForServer = {
//          imageURL: img
//        }
//        const messageJSON = JSON.stringify(messageForServer);
//        var messageBuff = new Buffer(messageJSON);
//        var encodedMessage = messageBuff.toString('base64');
//        const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getTwitterImage?params=" + encodedMessage
//        const response = await fetch(url);
//        const data = await response.json();
//
//        if (data.status == "success") {
//          const respData = data.data
//          const id = respData["id"].toString()
//          const twitterPic = respData["entities"]["media"][0]["display_url"]
//          text += "%0A%0A&url=" + encodeURI(twitterPic)
//        }
//        else {
//          text += "%0A%0A[Message for tweeter... upload this image manually: " + img + "]"
//        }
//        window.open(text, '_blank')
//      }
//      else {
//        content = content.replace(/\n/g, "%0A")
//        var text = "https://twitter.com/intent/tweet?text=" + content
//        text += "%0A%0ANFT: bitprofile.io/post/" + tokenId
//        window.open(text, '_blank')
//      }
//    }
//  }

  const handleCloseLoadingTransaction = (value) => {
    setOpenLoadingTransaction(false);
  };

  const handleCloseSuccessMintTransaction = (value) => {
    setOpenSuccessMintTransaction(false);
  }

  const openNFTDialog = (value) => {
    setOpenNFTToPostDialog(true)
  }

  const handleCloseNFTToPostDialog = (value) => {
    setOpenNFTToPostDialog(false)
  }

  const handleClosePostToTwitter = (value) => {
    setOpenPostToTwitter(false)
  }

  const handleCloseConnectTwitterAPI = (value) => {
    setOpenConnectTwitterAPI(false)
  }

  const handleClosePhoto = (value) => {
    setImageBlob()
    setPreviewImageBlob()
    setImgValue("")
    setUseNFTImage(false)
    setExternalNFTImg("")
  }

  return (
    <React.Fragment>
      <TextField
        value={mintInput}
        onInput={e => setMintInput(e.target.value)}
        sx={{ mt: 1, paddingTop: "5px", maxWidth: "90%", marginLeft: marginLeft }}
        id="outlined-basic"
        fullWidth
        multiline
        placeholder="Own and sell your thoughts"
        variant="standard"
        inputProps={{maxLength: 280, style: {fontSize: 18, padding: "5px"}}} // font size of input text
        InputLabelProps={{style: {fontSize: 18}}} // font size of input label
      />
      { previewImageBlob &&
        <ImagePreview previewImageBlob={previewImageBlob} handleClosePhoto={handleClosePhoto} />
      }
      <div style={{ height: "70px" }}>
        <Button sx={{ mt: 2, ml: 3, float: "left", height: "40px", textTransform: 'none', marginLeft: "4px" }} component="label">
          <InsertPhotoIcon/> <input value={imgValue} hidden accept="image/*" type="file" onChange={ (e) => { uploadFile(e) }} />
        </Button>

        <Button onClick={openNFTDialog} sx={{ mt: 2, float: "left", height: "40px", textTransform: 'none', marginLeft: "10px" }} component="label" >
        NFT <AddPhotoAlternateIcon style={{ marginLeft: "5px" }} />
        </Button>

        { preppingTransaction ?
            <Box sx={{ mt: 2, mr: 3, float: "right", height: "40px" }}><CircularProgress /></Box>
          :
           <Button onClick={post} sx={{ mt: 2, mr: 3, float: "right", borderRadius: "25px", fontWeight: 600, height: "40px" }} style={{textTransform: 'none'}} size="large" variant="contained">Mint</Button>
        }
      </div>
      <LoadingTransactionDialog
        open={openLoadingTransaction}
        onClose={handleCloseLoadingTransaction}
        transactionLink={dialogTransactionLink}
        transactionTitle="Your post is being minted"
      />

      <SuccessDialog
        open={openSuccessMintTransaction}
        onClose={handleCloseSuccessMintTransaction}
        link={dialogTransactionLink}
        title="Your post was minted!"
      />

      <NftToPostDialog
        open={openNFTToPostDialog}
        onClose={handleCloseNFTToPostDialog}
        setExternalNFTImg={setExternalNFTImg}
      />

      <PostToTwitterDialog
        open={openPostToTwitter}
        onClose={handleClosePostToTwitter}
        tokenId={postTokenId}
        content={postContent}
      />

      <ConnectAPITwitterDialog
        open={openConnectTwitterAPI}
        onClose={handleCloseConnectTwitterAPI}
        provider={provider}
        account={account}
      />

    </React.Fragment>
  )
}

export default CreatePost