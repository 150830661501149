import React, { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from "@mui/material";
import { ReserveProfileDialog } from "../Dialogs.js"
import { getVerifiedTwitterAccounts } from "../FirebaseRetrieval.js"

// When click reserve button
// 1. Ask to connect wallet
// 2. Say to verify their account, to tweet a message:
//      Prove that you control your address by tweeting a message
// 3. Paste URL of tweet
// 4. Say optional and ask for email address to get alerted about updates, or ask
//      them to follow you on twitter or join discord.

const Reserve = (props) => {
  const [elev, setElev] = useState(15);
  const [elev2, setElev2] = useState(15);
  const [openReserveProfileDialog, setOpenReserveProfileDialog] = useState(false)
  const [twitterAccounts, setTwitterAccounts] = useState([])
  const myRef = useRef(null)

  const handleCloseReserveProfileDialog = () => {
    setOpenReserveProfileDialog(false)
  }

  useEffect(() => {
    getVerifiedTwitterAccounts((twitter_accounts) => {
        twitter_accounts.sort((a, b) => {
            return b.followersCount - a.followersCount;
        });
        setTwitterAccounts(twitter_accounts)
    })
  }, [])

  return (
    <>
      <Grid container style={{ background: "linear-gradient(285deg, rgb(5, 0, 27) 0%, rgb(75 0 122) 100%)" }}>
        <Grid item xs={12}>
          <Box sx={{ marginTop: "40px" }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Paper
                 elevation={elev}
                 onMouseOver={() => setElev(24)}
                 onMouseOut={() => setElev(15)}
                 style={{
                  padding: "30px",
                  borderRadius: "10px",
                  background: "linear-gradient(71deg, rgb(160 63 255) 0%, rgb(96 45 179) 100%)",
                  color: "white",
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "600px",
                  maxWidth: "90%"
                 }}>
                  <h3 style={{ fontSize: "35px" }}>
                    Join the creator economy
                  </h3>
                  <h5 style={{ fontSize: "25px", marginTop: "20px" }}>
                    by reserving an early Collection Profile!
                  </h5>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                    elevation={elev2}
                    onMouseOver={() => setElev2(24)}
                    onMouseOut={() => setElev2(15)}
                    onClick={() => setOpenReserveProfileDialog(true)}
                    style={{
                        padding: "30px",
                        borderRadius: "10px",
                        marginTop: "50px",
                        background: "linear-gradient(71deg, rgb(197 37 255) 0%, rgb(145 0 255) 100%)",
                        color: "white",
                        marginRight: "auto",
                        marginLeft: "auto",
                        width: "600px",
                        maxWidth: "90%",
                        cursor: "pointer",
                        boxShadow: "0px 0px 20px 5px #ffffff30"
                    }}>
                    <Typography sx={{ display: { xs: 'none', md: 'block' }, fontSize: "25px", textAlign: "center" }}>Reserve a Profile <ArrowForwardIosIcon sx={{marginLeft: "20px"}} /></Typography>
                    <Typography sx={{ display: { xs: 'block', md: 'none' }, fontSize: "20px", textAlign: "center" }}>Reserve a Profile <ArrowForwardIosIcon sx={{marginLeft: "20px"}} /></Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <div style={{ width: "600px", maxWidth: "100%", marginLeft: "10px", marginRight: "10px", marginTop: "50px" }} >
              <div style={{ borderStyle: "solid", borderWidth: "0px", borderRadius: "10px", padding: "10px", background: "linear-gradient(133deg, rgb(153 72 255) 0%, rgb(106 0 131) 100%)" }} >
                <h5 style={{ fontSize: "25px", textAlign: "left", marginLeft: "20px", marginTop: "10px", marginBottom: "20px", color: "white" }}>
                  What are Collection Profiles?
                </h5>
                <Paper variant="outlined" style={{ borderRadius: "20px", marginTop: "10px", fontSize: "18px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Our social profiles and posts are valuable! We shouldn't let someone else own and arbitrarily delete them.</div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "20px", marginTop: "10px", fontSize: "18px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}><strong>We should own our profiles and posts!</strong></div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "20px", marginTop: "10px", fontSize: "18px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Collection Profiles blend a Twitter-like Profile with an OpenSea Collection.</div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "20px", marginTop: "10px", fontSize: "18px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Your Collection Profile is made up of your post NFTs. These are NFTs in the form of posts. They're free to create, owned by you, and tradeable.</div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "20px", marginTop: "10px", fontSize: "18px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Each profile shows the floor price, holders, etc, for its post NFTs.</div>
                </Paper>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <div style={{marginTop: "50px"}}></div>
          </Box>
        </Grid>
      </Grid>
      <ReserveProfileDialog 
        open={openReserveProfileDialog}
        onClose={handleCloseReserveProfileDialog}
      />
    </>
  )
}

export default Reserve;