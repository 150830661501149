import React, { useEffect, useState, useRef } from "react";
import { useHistory, Router, Link } from "react-router-dom";
import {createBrowserHistory} from 'history';

import ProfileNFT from "../Profile/Avatar.js"
import FollowButton from "../Profile/FollowButton.js"

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import CircularProgress from '@mui/material/CircularProgress';

export const Notification = (props) => {

  let history = useHistory();

  let goToProfile = (e, name) => {
    e.stopPropagation();
    history.push("/" + name)
  }

  let goToPost = (e, tokenId) => {
    e.stopPropagation();
    history.push("/post/" + tokenId)
  }

  return (
    <React.Fragment>
      <Paper
         elevation={0}
         variant="outlined"
         sx={{ marginTop: '10px', maxWidth: "100%", borderColor: props.borderColor ? props.borderColor : "#ececec", boxShadow: props.boxShadow }}>
        <ListItem>
          <Grid container spacing={2}>
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <ListItemAvatar sx={{ marginTop: "0px" }} >
                  { props.notificationType == "follow" ?
                      <ProfileNFT profileAccount={props.follower} widthpx={"40px"}/>
                    : <ProfileNFT profileAccount={props.poster} widthpx={"40px"}/>
                  }
                </ListItemAvatar>
              </Grid>
              {
                props.notificationType == "follow" ?
                  <Grid item xs={8}>
                    <div style={{ fontSize: "16px", marginLeft: "3px", marginTop: "10px"}} >
                      <span style={{fontWeight: "bold", cursor: "pointer"}} onClick={ (e) => { goToProfile(e, props.follower) }} >{props.followerName}</span> followed you
                    </div>
                  </Grid>
                :
                  <Grid item xs={12}>
                    <>
                      <div style={{ fontSize: "16px", marginLeft: "3px", marginTop: "10px"}} >
                        <span style={{fontWeight: "bold", cursor: "pointer"}} onClick={ (e) => { goToProfile(e, props.poster) }}  >{props.name}</span>
                        <span style={{cursor: "pointer"}} onClick={ (e) => { goToPost(e, props.tokenId)}}> {props.notificationType == "reply" ? "replied to your post" : "mentioned you" } </span>
                      </div>
                      <div style={{ fontSize: "15px", marginLeft: "2px", marginTop: "5px", color: "#333", cursor: "pointer"}}
                           onClick={ (e) => { goToPost(e, props.tokenId)}} >
                        {props.content}
                      </div>
                    </>
                  </Grid>
              }
              { props.notificationType == "follow" &&
                <Grid item xs={4}>
                  <FollowButton
                    style = {{
                      marginLeft: "30px",
                      marginTop: "45px",
                      float: "right",
                      marginTop: "0px",
                      width: "90px",
                      borderRadius: "20px",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: 'none'
                    }}
                    provider={props.provider}
                    account={props.account}
                    profileAccount={props.follower}
                   />
                </Grid>
              }
            </Grid>
          </Grid>
        </ListItem>
      </Paper>
    </React.Fragment>
  );
}

export default {Notification}