import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TwitterIcon from '@mui/icons-material/Twitter';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import Post from "../Post/Post.js"
import CreatePost from "../Post/CreatePost.js"
import {  getPostsForFeed,
          getPostData,
          getNextPostsForFeed,
          getPostsForGlobal,
          getNextPostsForGlobal,
          getTopTodayPostsForGlobal,
          getTopMonthPostsForGlobal,
          getTopYearPostsForGlobal,
          getPostsForFeatured,
          getNextPostsForFeatured,
          getPostsForBAYC,
          getNextPostsForBAYC,
          getPostsForCryptoPunk,
          getNextPostsForCryptoPunk,
          getPostsForSkulls,
          getNextPostsForSkulls,
          getPostsForCoolCats,
          getNextPostsForCoolCats,
          getPostsForENS,
          getNextPostsForENS,
          getTopCollectors,
       } from "../FirebaseRetrieval.js"

import ProfileNFT from "../Profile/Avatar.js"
import { getProfileNameFromAddress } from "../ContractHelpers.js"
import FollowButton from "../Profile/FollowButton.js"
import {isMobile} from 'react-device-detect';

import { getFromCache, shouldUseCache, cache } from "../Cache.js"

const Web3 = require('web3');

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const useStyles = makeStyles({
  select: {
    "&:after": {
      borderStyle: "none",
      borderBottomColor: "darkred",
    },
    "& .MuiSvgIcon-root": {
      borderStyle: "none",
      color: "darkred",
    },
    "& .MuiOutlinedInput-root": {
      borderStyle: "none",
      color: "transparent"
    }
  },
});

const scrollToPosition = (y) => {
  const isMobile = (window.innerHeight / window.innerWidth) > 1
  if (isMobile) {
    window.scroll(0, y)
  }
  else {
    document.getElementById("list").scroll(0, y)
  }
}

var postsSet = new Set()

function HomeFeed({ profileAccount, account, provider }) {
  const [fetchedFeedPosts, setFetchedFeedPosts] = useState(false)
  const [isFetchingNextPosts, setIsFetchingNextPosts] = useState(false)
  const [isLoadingFromCache, setIsLoadingFromCache] = useState(false)
  const [newPost, setNewPost] = useState();
  const [postDeleted, setPostDeleted] = useState();
  const [feedPosts, setFeedPosts] = useState([]);
  const [collectorNames, setCollectorNames] = useState([]);
  const [collectors, setCollectors] = useState([]);
  const [fullPagesScrolled, setFullPagesScrolled] = useState(0)
  const [lastPostTimestamp, setLastPostTimestamp] = useState(0)
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const openOptions = Boolean(anchorElOptions);

  var fetchedFirstPosts = useRef(false);

  const classes = useStyles();

  const location = useLocation();
  let history = useHistory();

  const handleScroll = () => {
    const isMobile = (window.innerHeight / window.innerWidth) > 1
    if (isMobile) {
      const positionY = window.scrollY;
      const listHeight = window.innerHeight;

      const page = parseInt(positionY/listHeight)
      if (page > fullPagesScrolled) {
        setFullPagesScrolled(page)
      }
    }
    else {
      const positionY = document.getElementById("list").scrollTop;
      const listHeight = document.getElementById("list").offsetHeight / 2;

      const page = parseInt(positionY/listHeight)
      if (page > fullPagesScrolled) {
        setFullPagesScrolled(page)
      }
    }
  };

  function clearFeedCache() {
    cache("home_feed_pos", 0)
    localStorage.removeItem("feedPosts")
    localStorage.removeItem("globalPosts")
    fetchedFirstPosts.current = false
    setFullPagesScrolled(0)
  }

  const handleClickOptions = (e) => {
    setAnchorElOptions(e.currentTarget);
  }

  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };

  useEffect(() => {
    const isMobile = (window.innerHeight / window.innerWidth) > 1
    if (isMobile) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    else {
      document.getElementById("list").addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        document.getElementById("list").removeEventListener('scroll', handleScroll);
      };
    }
  }, [fullPagesScrolled]);

  // This fetches the posts data and filters for duplicated
  async function fetchPostsDataFirebase(posts, cb) {
    if (posts.length == 1) {
      getPostData(posts[0].key, (postDict) => {
        try {
          postDict["feedTimestamp"] = posts[0].timestamp;
          if (posts[0].poster) {
            postDict["poster"] = posts[0].poster
          }
          if (posts[0].reposter) {
            postDict["reposter"] = posts[0].reposter
          }
          if (postsSet.has(postDict.tokenId)) {
            cb([])
          }
          else {
            postsSet.add(postDict.tokenId)
            cb([postDict])
          }
        }
        catch {
          cb([])
        }
      })
    }
    else {
      // get post data for latest post
      getPostData(posts[0].key, (postDict) => {
        try {
          postDict["feedTimestamp"] = posts[0].timestamp;
          if (posts[0].poster) {
            postDict["poster"] = posts[0].poster
          }
          if (posts[0].reposter) {
            postDict["reposter"] = posts[0].reposter
          }
          var poppedPosts = [...posts];
          poppedPosts.shift();
          fetchPostsDataFirebase(poppedPosts, (postsArr) => {
            if (!postsSet.has(postDict.tokenId)) {
              postsArr.push(postDict)
              postsSet.add(postDict.tokenId)
            }
            cb(postsArr)
          })
        }
        catch {
          var poppedPosts = [...posts];
          poppedPosts.shift();
          fetchPostsDataFirebase(poppedPosts, (postsArr) => {
            cb(postsArr)
          })
        }
      })
    }
  }

  useEffect(() => {
    if (fullPagesScrolled > 0 && feedPosts.length > 0) {
      // add new set of post
      const earliestPostTimestamp = feedPosts[feedPosts.length-1].postTimestamp
      if (earliestPostTimestamp == lastPostTimestamp) {
        return;
      }
      setIsFetchingNextPosts(true)
      if (location.pathname == "/following") {
        getNextPostsForFeed(account, earliestPostTimestamp, (posts) => {
          setLastPostTimestamp(earliestPostTimestamp)
          if (posts.length == 0 ) {
            setIsFetchingNextPosts(false)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setIsFetchingNextPosts(false)
            setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
            cache("feedPosts", [...feedPosts].concat(postsArr));
          })
        })
      }
      else {
        if (location.pathname == "/new") {
          getNextPostsForGlobal(earliestPostTimestamp, (posts) => {
            if (posts.length == 0 ) {
              setIsFetchingNextPosts(false)
              return
            }
            fetchPostsDataFirebase(posts, (postsArr) => {
              postsArr.sort((a, b) => {
                  return b.feedTimestamp - a.feedTimestamp;
              });
              setIsFetchingNextPosts(false)
              setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
              cache("globalPosts", [...feedPosts].concat(postsArr));
            })
          })
        }
        else if (location.pathname == "/featured") {
         getNextPostsForFeatured(earliestPostTimestamp, (posts) => {
           if (posts.length == 0 ) {
             setIsFetchingNextPosts(false)
             return
           }
           fetchPostsDataFirebase(posts, (postsArr) => {
             postsArr.sort((a, b) => {
                 return b.feedTimestamp - a.feedTimestamp;
             });
             setIsFetchingNextPosts(false)
             setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
             cache("globalPosts", [...feedPosts].concat(postsArr));
           })
         })
        }
        else if (location.pathname == "/cryptopunkHolders") {
         getNextPostsForCryptoPunk(earliestPostTimestamp, (posts) => {
           if (posts.length == 0 ) {
             setIsFetchingNextPosts(false)
             return
           }
           fetchPostsDataFirebase(posts, (postsArr) => {
             postsArr.sort((a, b) => {
                 return b.feedTimestamp - a.feedTimestamp;
             });
             setIsFetchingNextPosts(false)
             setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
             cache("globalPosts", [...feedPosts].concat(postsArr));
           })
         })
        }
        else if (location.pathname == "/baycHolders") {
         getNextPostsForBAYC(earliestPostTimestamp, (posts) => {
           if (posts.length == 0 ) {
             setIsFetchingNextPosts(false)
             return
           }
           fetchPostsDataFirebase(posts, (postsArr) => {
             postsArr.sort((a, b) => {
                 return b.feedTimestamp - a.feedTimestamp;
             });
             setIsFetchingNextPosts(false)
             setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
             cache("globalPosts", [...feedPosts].concat(postsArr));
           })
         })
        }
        else if (location.pathname == "/skullHolders") {
         getNextPostsForSkulls(earliestPostTimestamp, (posts) => {
           if (posts.length == 0 ) {
             setIsFetchingNextPosts(false)
             return
           }
           fetchPostsDataFirebase(posts, (postsArr) => {
             postsArr.sort((a, b) => {
                 return b.feedTimestamp - a.feedTimestamp;
             });
             setIsFetchingNextPosts(false)
             setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
             cache("globalPosts", [...feedPosts].concat(postsArr));
           })
         })
        }
        else if (location.pathname == "/coolcatHolders") {
         getPostsForCoolCats(earliestPostTimestamp, (posts) => {
           if (posts.length == 0 ) {
             setIsFetchingNextPosts(false)
             return
           }
           fetchPostsDataFirebase(posts, (postsArr) => {
             postsArr.sort((a, b) => {
                 return b.feedTimestamp - a.feedTimestamp;
             });
             setIsFetchingNextPosts(false)
             setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
             cache("globalPosts", [...feedPosts].concat(postsArr));
           })
         })
        }
        else if (location.pathname == "/ensHolders") {
         getNextPostsForENS(earliestPostTimestamp, (posts) => {
           if (posts.length == 0 ) {
             setIsFetchingNextPosts(false)
             return
           }
           fetchPostsDataFirebase(posts, (postsArr) => {
             postsArr.sort((a, b) => {
                 return b.feedTimestamp - a.feedTimestamp;
             });
             setIsFetchingNextPosts(false)
             setFeedPosts(feedPosts => [...feedPosts].concat(postsArr));
             cache("globalPosts", [...feedPosts].concat(postsArr));
           })
         })
        }
      }
    }
  }, [fullPagesScrolled]);

  // Gets the initial posts. Should be merged with the dynamic one.
  useEffect(() => {
    async function fetchFeedPosts(account) {
      getPostsForFeed(account, (posts) => {
        if (posts.length == 0 ) {
          setFetchedFeedPosts(true)
          return
        }
        fetchPostsDataFirebase(posts, (postsArr) => {
          // sort postsArr by time
          postsArr.sort((a, b) => {
              return b.feedTimestamp - a.feedTimestamp;
          });
          setFeedPosts(postsArr)
          setFetchedFeedPosts(true)

          // Cache the feed posts for being easily loadable when going back
          // Do not reload when going back. Only reload when button to reload is
          // clicked or when a real refresh occurs.
          cache("feedPosts", postsArr)
        })
      })
    }

    async function fetchCollectors() {
      getTopCollectors((collectors) => {
        collectors.sort((a, b) => {
          return b.num_collected - a.num_collected;
        });
        setCollectors(collectors)
        for (var i=0; i<collectors.length; i++) {
          const address = collectors[i].address
          getProfileNameFromAddress(address, (name) => {
            setCollectorNames(prevState => ({
                ...prevState,
                [address]: name
            }));
          });
        }
        setFetchedFeedPosts(true)
      })
    }

    async function fetchGlobalPosts() {
      if (location.pathname == "/new") {
        getPostsForGlobal((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/topToday") {
        getTopTodayPostsForGlobal((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by upvotes
            postsArr.sort((a, b) => {
                return b.upvotesCount - a.upvotesCount;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/topMonth") {
        getTopMonthPostsForGlobal((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by upvotes
            postsArr.sort((a, b) => {
                return b.upvotesCount - a.upvotesCount;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/topYear") {
        getTopYearPostsForGlobal((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by upvotes
            postsArr.sort((a, b) => {
                return b.upvotesCount - a.upvotesCount;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/featured") {
        getPostsForFeatured((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/cryptopunkHolders") {
        getPostsForCryptoPunk((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/baycHolders") {
        getPostsForBAYC((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/skullHolders") {
        getPostsForSkulls((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/coolcatHolders") {
        getPostsForCoolCats((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/ensHolders") {
        getPostsForENS((posts) => {
          if (posts.length == 0 ) {
            setFetchedFeedPosts(true)
            return
          }
          fetchPostsDataFirebase(posts, (postsArr) => {
            // sort postsArr by time
            postsArr.sort((a, b) => {
                return b.feedTimestamp - a.feedTimestamp;
            });
            setFeedPosts(postsArr)
            setFetchedFeedPosts(true)

            cache("globalPosts", postsArr)
          })
        })
      }
      else if (location.pathname == "/nfpHolders") {
        setFetchedFeedPosts(true)
      }
    }

    if (fetchedFirstPosts.current == false) {
      setFeedPosts([])
      setFetchedFeedPosts(false)
      postsSet = new Set()

      if (location.pathname == "/following" && account) {
        fetchedFirstPosts.current = true
        var cache_name = "feedPosts"
        if (shouldUseCache(cache_name, 60 * 60 * 24)) {
          const cachedArr = getFromCache(cache_name)

          if (cachedArr.length > 0) {
            setFeedPosts(cachedArr)
            setFetchedFeedPosts(true)

            // should cache postsSet too

            // get the position and scroll to it
            // out of order because the image take a while to load
            var cache_name = "home_feed_pos"
            if (shouldUseCache(cache_name, 60 * 60 * 24)) {
              const pos = getFromCache(cache_name)
              if (pos > window.screen.height/2) {
                // setIsLoadingFromCache(true)
                setTimeout(() => {
                  // setIsLoadingFromCache(false)
                  scrollToPosition(pos)
                }, 40)
              }
            }
          }
          else {
            fetchFeedPosts(account);
          }
        }
        else {
          fetchFeedPosts(account);
        }
      }
      else if (location.pathname == "/collectors") {
        fetchedFirstPosts.current = true
        fetchCollectors();
      }
      else {
        fetchedFirstPosts.current = true
        var cache_name = "globalPosts"
        if (shouldUseCache(cache_name, 60 * 2) && location.pathname == "/new") {
          const cachedArr = getFromCache(cache_name)

          if (cachedArr.length > 0) {
            setFeedPosts(cachedArr)
            setFetchedFeedPosts(true)

            // should cache postsSet too

            // get the position and scroll to it
            // out of order because the image take a while to load
            var cache_name = "home_feed_pos"
            if (shouldUseCache(cache_name, 60 * 60 * 60 * 2)) {
              const pos = getFromCache(cache_name)
              // setIsLoadingFromCache(true)
              setTimeout(() => {
                // setIsLoadingFromCache(false)
                scrollToPosition(pos)
              }, 40)
            }
          }
          else {
            fetchGlobalPosts();
          }
        }
        else {
          fetchGlobalPosts();
        }
      }
    }
  }, [account, newPost, postDeleted, location.pathname, setNewPost]);

  const followingClicked = () => {
    clearFeedCache()
    window.location.href = "/following"
//    history.push("/")
  }

  const collectorsClicked = () => {
    clearFeedCache()
    window.location.href = "/collectors"
//    history.push("/topToday")
  }

  const newClicked = () => {
    clearFeedCache()
    window.location.href = "/new"
//    history.push("/new")
  }

  const featuredClicked = () => {
    clearFeedCache()
    window.location.href = "/featured"
  }

  const topTodayClicked = () => {
    clearFeedCache()
    window.location.href = "/topToday"
//    history.push("/topToday")
  }

  const topMonthClicked = () => {
    clearFeedCache()
    window.location.href = "/topMonth"
//    history.push("/topMonth")
  }

  const nfpClicked = () => {
    clearFeedCache()
    window.location.href = "/nfpHolders"
  }

  const cryptopunksClicked = () => {
    clearFeedCache()
    window.location.href = "/cryptopunkHolders"
  }

  const baycClicked = () => {
    clearFeedCache()
    window.location.href = "/baycHolders"
  }

  const skullsClicked = () => {
    clearFeedCache()
    window.location.href = "/skullHolders"
  }

  const coolcatsClicked = () => {
    clearFeedCache()
    window.location.href = "/coolcatHolders"
  }

  const ensClicked = () => {
    clearFeedCache()
    window.location.href = "/ensHolders"
  }

  const handleOpenProfile = (address) => {
    history.push("/" + address)
  }

  return (
    <React.Fragment>
      <div id="list" style={{marginTop: "5px", height: ((window.innerHeight / window.innerWidth) > 1) ? "auto": "90vh", overflowY: ((window.innerHeight / window.innerWidth) > 1) ? "visible" : "auto" }}>
        <Paper square elevation={0}  sx={{ pb: '50px', width: 500, maxWidth: "100%", margin: 'auto'}}>
          <div style={{marginTop: "30px"}}></div> {/* Some padding */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "15px" }}>
            <Button onClick={followingClicked} style={{textTransform: 'none', marginRight: "10px", width: "100px"}} size="large" variant={location.pathname=="/following" ? "outlined" : "text"}>Following</Button>
            <Button onClick={newClicked} style={{textTransform: 'none', marginLeft: "10px", width: "100px"}} size="large" variant={(location.pathname!="/following" && location.pathname!="/collectors") ? "outlined" : "text"}>Community</Button>
            <Button onClick={collectorsClicked} style={{textTransform: 'none', marginLeft: "10px", width: "100px"}} size="large" variant={location.pathname=="/collectors" ? "outlined" : "text"}>Collectors</Button>
          </div>
          { (location.pathname != "/following" && location.pathname != "/collectors") &&
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px" }}>
                <div style={{ width: "150px", height: "1px", backgroundColor: "#eee" }} ></div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "35px" }}>
                <Button onClick={nfpClicked}
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/nfpHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/nfpHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px",
                    }}
                    size="large" variant="text">
                  NFP DAO
                </Button>
                { location.pathname == "/topToday" || location.pathname == "/topMonth" || location.pathname == "/topYear" || location.pathname == "/featured" ?
                  <div>
                    <Button
                     onClick={(e) => handleClickOptions(e)}
                     id="basic-button"
                     aria-controls="basic-menu"
                     aria-haspopup="true"
                     aria-expanded={openOptions ? 'true' : undefined}
                     style={{
                       fontSize: "12px",
                       textTransform: 'none',
                       marginRight: "10px",
                       width: "100px",
                       color: (location.pathname == "/topToday" || location.pathname == "/topMonth" || location.pathname == "/topYear" || location.pathname == "/featured") ? "white": "#1876d1",
                       backgroundColor: (location.pathname == "/topToday" || location.pathname == "/topMonth" || location.pathname == "/topYear" || location.pathname == "/featured") ? "#1876d1": "white",
                       borderStyle: "solid",
                       borderRadius: "5px"
                     }}
                     >
                      { location.pathname != "/featured" && <span style={{ marginRight: "2px" }} >Top</span> }
                      { location.pathname == "/topToday" ? "Today" : location.pathname == "/topMonth" ? "Month" : location.pathname == "/topYear" ? "Year" : "Featured" }
                      <KeyboardArrowDownIcon/>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElOptions}
                      open={openOptions}
                      onClose={handleCloseOptions}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                     >
                      <MenuItem onClick={() => {window.location.href="/featured"}}>Featured</MenuItem>
                      <MenuItem onClick={() => {window.location.href="/topToday"}}>Top Today</MenuItem>
                      <MenuItem onClick={() => {window.location.href="/topMonth"}}>Top Month</MenuItem>
                      <MenuItem onClick={() => {window.location.href="/topYear"}}>Top Year</MenuItem>
                    </Menu>
                  </div>
                  :
                  <Button onClick={featuredClicked}
                    style={{
                      fontSize: "12px",
                      textTransform: 'none',
                      marginRight: "10px",
                      width: "100px",
                      color: location.pathname=="/featured" ? "white": "#1876d1",
                      backgroundColor: location.pathname=="/featured" ? "#1876d1": "white",
                      borderStyle: "solid",
                      borderRadius: "5px"
                      }}
                      size="large" variant="text" >
                    Featured
                  </Button>
                }
                <Button onClick={newClicked}
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/new" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/new" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  New
                </Button>
              </div>
              {/*
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "15px" }}>
                <Button onClick={cryptopunksClicked}
                  style={{
                    fontSize: "11px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/cryptopunkHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/cryptopunkHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  CryptoPunk
                </Button>
                <Button onClick={baycClicked}
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/baycHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/baycHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  BAYC
                </Button>
                <Button onClick={ensClicked}
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/ensHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/ensHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  ENS
                </Button>
              </div> */} 
              { /*
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "15px" }}>
                <Button onClick={skullsClicked}
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/skullHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/skullHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  CryptoSkulls
                </Button>
                <Button onClick={coolcatsClicked}
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/coolcatHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/coolcatHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  CoolCats
                </Button>
                <Button
                  style={{
                    fontSize: "12px",
                    textTransform: 'none',
                    marginRight: "10px",
                    width: "100px",
                    color: location.pathname=="/wowHolders" ? "white": "#1876d1",
                    backgroundColor: location.pathname=="/wowHolders" ? "#1876d1": "white",
                    borderStyle: "solid",
                    borderRadius: "5px"
                    }}
                    size="large" variant="text" >
                  WorldOfWomen
                </Button>
              </div> */}
            </>
          }
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px" }}>
            <div style={{ width: "100%", height: "1px", backgroundColor: "#eee" }} ></div>
          </div>
          {(location.pathname != "/collectors") &&
            <div style={{marginTop: "20px"}}>
              <CreatePost provider={provider} account={account} parentPostId={0} setNewPost={setNewPost} marginLeft={"20px"} />
            </div>
          }
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "20px" }}>
            <div style={{ width: "100%", height: "1px", backgroundColor: "#eee" }} ></div>
          </div>
          { isLoadingFromCache &&
            <LinearProgress sx={{ width: '100%', marginTop: "20px" }} />
          }
          { location.pathname == "/nfpHolders" &&
            <Paper elevation={3} sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "80px",
              textAlign: "center",
              color: "white",
              height: "100px",
              marginLeft: "10px",
              marginRight: "10px",
              background: "linear-gradient(71deg, rgb(177 37 255) 0%, rgb(145 0 255) 100%)"
            }}>
              <h5 style={{marginTop: "5px"}}>
                Coming soon. Stay tuned. <Button onClick={() => {window.open("https://twitter.com/nfposts_eth")} } style={{ textTransform: 'none', color: "white" }}><TwitterIcon/></Button>
              </h5>
            </Paper>
          }
          { !fetchedFeedPosts ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "85px" }}>
              <CircularProgress />
            </div>
            :
              location.pathname == "/collectors"
              ?
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "30px" }}>
                    <p style={{ fontSize: "20px" }}>Top Collectors</p>
                  </div>
                  <List sx={{ pt: 0, marginTop: "5px"}}>
                    {collectors.map((item, index) => (
                      <ListItem key={item.address}>
                        <p style={{ fontSize: "20px", margin: "10px", marginRight: "20px" }} >{index + 1}</p>
                        <ListItemAvatar style={{ cursor: "pointer"}} onClick={() => handleOpenProfile(item.address)}>
                          <ProfileNFT profileAccount={item.address} widthpx={"40px"}/>
                        </ListItemAvatar>
                        <ListItemText style={{ cursor: "pointer"}} onClick={() => handleOpenProfile(item.address)} primary={collectorNames[item.address]} />
                        <FollowButton
                          style = {{
                            marginLeft: "30px",
                            marginTop: "45px",
                            float: "right",
                            marginTop: "0px",
                            width: "90px",
                            borderRadius: "20px",
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: 'none'
                          }}
                          provider={provider}
                          account={account}
                          profileAccount={item.address}
                         />
                      </ListItem>
                    ))}
                     <div key="padding" style={{height: "50vh"}} ></div> {/* padding at bottom */}
                  </List>
                </>
              :
                <List sx={{ mb: 2, mt: 1, ml: 1, mr: 1 }}>
                  {feedPosts.map(({ tokenId, poster, name, content, postTimestamp, chain, owner, ownerName, reposter, isNSFW }) => (
                    <React.Fragment key={tokenId}>
                      <Post
                        account={account}
                        provider={provider}
                        tokenId={tokenId}
                        poster={poster}
                        name={name}
                        owner={owner}
                        ownerName={ownerName}
                        content={content}
                        isNSFW={isNSFW}
                        postTimestamp={postTimestamp}
                        posCacheName={"home_feed_pos"}
                        chain={chain}
                        reposter={reposter}
                        setPostDeleted={setPostDeleted}
                      />
                    </React.Fragment>
                  ))}
                  { (isFetchingNextPosts && (location.pathname=="/following" || location.pathname=="/new")) &&
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "50px" }}>
                        <CircularProgress />
                      </div>
                      <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
                    </>
                  }
                  <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
                </List>
          }
        </Paper>
      </div>
    </React.Fragment>
  );
}

export default HomeFeed;




