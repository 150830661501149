import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import CheckIcon from '@mui/icons-material/Check';
import TodoIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import logo from '../icon.png';
import logo4 from '../icon4.png';
import FakePost from '../Post/FakePost.js'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import BuildIcon from '@mui/icons-material/Build';

const Landing = (props) => {
  const [elev, setElev] = React.useState(15);
  const [elev2, setElev2] = React.useState(15);
  return (
    <>
    {/*
      <div style={{
        background: "radial-gradient(circle, rgba(35,136,244,0.598) 0%, rgba(80,33,207,0.155) 52%)",
        position: "fixed",
        width: "100%",
        height: "500%",
        left: "0",
        top: "-70%",
        zIndex: "0"
      }} ></div> */}
      <Grid container style={{ background: "linear-gradient(285deg, rgb(5, 0, 27) 0%, rgb(75 0 122) 100%)" }}>
        <Grid item xs={12}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: "100px" }}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Paper
                 elevation={elev}
                 onMouseOver={() => setElev(24)}
                 onMouseOut={() => setElev(15)}
                 style={{
                  float: "right",
                  padding: "30px",
                  borderRadius: "10px",
                  background: "linear-gradient(71deg, rgb(160 63 255) 0%, rgb(96 45 179) 100%)",
                  color: "white",
                  marginRight: "30px"
                 }}>
                  <h3 style={{ fontSize: "35px" }}>
                    <img style={{width: "40px", height: "40px", marginRight: "15px", marginTop: "-5px", marginLeft: "2px"}} src={logo4} />
                    Non Fungible Posts
                  </h3>
                  <h5 style={{ fontSize: "25px", marginTop: "20px" }}>
                    An Ethereum social network.
                  </h5>
                  <h5 style={{ fontSize: "25px", marginTop: "10px" }}>
                    Write, own, and sell NFPs.
                  </h5>
                  <h5 style={{ fontSize: "25px", marginTop: "10px" }}>
                    The future of NFT is NFP.
                  </h5>
                  <div style={{ marginTop: "50px", display: 'flex', justifyContent: "center" }} >
                    <Button onClick={() => {window.open("https://twitter.com/nfposts_eth")} } style={{ boxShadow: "none", textTransform: 'none', borderStyle: "solid", borderWidth: "2px", borderColor: "white", backgroundColor: "transparent", borderRadius: "15px" }} variant="contained"><TwitterIcon/><span style={{ marginLeft: "15px", fontSize: "20px" }}>Twitter</span></Button>
                    <Button onClick={() => {window.open("https://discord.gg/bhQxhMHE3r")} } style={{ boxShadow: "none", textTransform: 'none', marginLeft: "30px", borderStyle: "solid", borderWidth: "2px", borderColor: "white", backgroundColor: "transparent", borderRadius: "15px" }} variant="contained"><FontAwesomeIcon icon={faDiscord} style={{ width: "25px", height: "25px" }} /><span style={{ marginLeft: "15px", fontSize: "20px" }}>Join Discord</span></Button>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <div style={{ float: "left", width: "450px", marginTop: "30px", marginLeft: "30px" }}>
                  <FakePost tokenId={"40083515968046259622"} name={"nfposts.eth"} ownerName={"andreih.eth"} content={"A Non Fungible Post is a whole new type of NFT!\n\nNFPs can hold text, images, gifs, and videos. All data is stored on-chain so you can rest easy knowing that you have full control over your posts.\n\nClick the OpenSea icon below to view this NFP on OpenSea!"} postTimestamp={1639792166} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Paper
                 elevation={elev2}
                 onMouseOver={() => setElev2(24)}
                 onMouseOut={() => setElev2(15)}
                 onClick={() => window.location.href="/reserve"}
                 style={{
                  float: "right",
                  padding: "30px",
                  borderRadius: "10px",
                  background: "linear-gradient(71deg, rgb(37 114 255) 0%, rgb(145 0 255) 100%)",
                  color: "white",
                  width: "400px",
                  marginRight: "30px",
                  cursor: "pointer"
                 }}>
                  <h3 style={{ fontSize: "25px" }}>
                    Join the creator economy
                  </h3>
                  <h5 style={{ fontSize: "20px", marginTop: "20px" }}>
                    Reserve a Collection Profile
                  </h5>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper
                 elevation={elev2}
                 onMouseOver={() => setElev2(24)}
                 onMouseOut={() => setElev2(15)}
                 onClick={() => window.location.href="/new"}
                 style={{
                  float: "left",
                  padding: "30px",
                  borderRadius: "10px",
                  background: "linear-gradient(71deg, rgb(177 37 255) 0%, rgb(145 0 255) 100%)",
                  color: "white",
                  width: "400px",
                  marginTop: "50px",
                  marginLeft: "30px",
                  cursor: "pointer"
                 }}>
                  <h3 style={{ fontSize: "25px", textAlign: "center" }}>
                    Launch Testnet App
                  </h3>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: "center", marginTop: "40px" }}>
            <h3 style={{ fontSize: "30px", color: "white" }}>
              <img style={{width: "35px", height: "35px", marginRight: "10px", marginTop: "-5px"}} src={logo4} />
              Non Fungible Posts
            </h3>
            <span style={{ fontSize: "20px", marginLeft: "10px" }}>
              <Button onClick={() => {window.open("https://twitter.com/nfposts_eth")} } style={{ textTransform: 'none', color: "white" }}><TwitterIcon/></Button>
              <Button onClick={() => {window.open("https://discord.gg/bhQxhMHE3r")} } style={{ textTransform: 'none', color: "white" }}><FontAwesomeIcon icon={faDiscord} style={{ width: "25px", height: "25px" }} /></Button>
            </span>
            <div style={{ backgroundColor: "rgb(167 162 255 / 12%)", borderRadius: "20px", margin: "15px", padding: "10px", marginTop: "60px" }} > 
              <h5 style={{ fontSize: "20px", marginTop: "10px", color: "white" }}>
                An Ethereum social network.
              </h5>
              <h5 style={{ fontSize: "20px", marginTop: "10px", color: "white" }}>
                Write, own, and sell NFPs.
              </h5>
              <h5 style={{ fontSize: "20px", marginTop: "10px", color: "white" }}>
                The future of NFT is NFP.
              </h5>
            </div>
            <Paper
             elevation={elev2}
             onMouseOver={() => setElev2(24)}
             onMouseOut={() => setElev2(15)}
             onClick={() => window.location.href="/new"}
             style={{
              padding: "30px",
              margin: "15px",
              borderRadius: "10px",
              marginTop: "50px",
              background: "linear-gradient(71deg, rgb(37 114 255) 0%, rgb(145 0 255) 100%)",
              color: "white",
              cursor: "pointer"
             }}>
              <h3 style={{ fontSize: "25px", textAlign: "center" }}>
                Launch Testnet App
              </h3>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <div style={{ margin: "15px", marginTop: "20px" }}>
                  <FakePost tokenId={"40083515968046259622"} name={"nfposts.eth"} ownerName={"andreih.eth"} content={"A Non Fungible Post is a whole new type of NFT!\n\nNFPs can hold text, images, gifs, and videos. All data is stored on-chain so you can rest easy knowing that you have full control over your posts.\n\nClick the OpenSea icon below to view this NFP on OpenSea!"} postTimestamp={1639792166} />
              </div>
            </Box>
            <Paper
             elevation={elev2}
             onMouseOver={() => setElev2(24)}
             onMouseOut={() => setElev2(15)}
             onClick={() => window.location.href="/reserve"}
             style={{
              padding: "30px",
              margin: "15px",
              borderRadius: "10px",
              marginTop: "50px",
              background: "linear-gradient(71deg, rgb(197 37 255) 0%, rgb(145 0 255) 100%)",
              color: "white",
              cursor: "pointer"
             }}>
              <h3 style={{ fontSize: "25px" }}>
                Join the creator economy
              </h3>
              <h5 style={{ fontSize: "20px", marginTop: "20px" }}>
                Reserve a Profile Collection
              </h5>
            </Paper>
          </Box>
        </Grid>
        {/*
        <Grid item xs={12}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, textAlign: "center", marginTop: "100px" }}>
            <Paper
             style={{
              padding: "30px",
              borderRadius: "0px",
              backgroundColor: "#3a29c7",
              color: "white"
             }}>
              <h3 style={{ fontSize: "35px" }}>
                <VisibilityIcon style={{ marginRight: "10px", width: "40px", height: "40px", marginTop: "-5px" }} />
                Be visible in the crypto community!
              </h3>
              <h5 style={{ fontSize: "25px", marginTop: "20px" }}>
                4000 utility NFTs will be freely claimable by our first users.
              </h5>
              <h5 style={{ fontSize: "25px", marginTop: "10px" }}>
                Holding one showcases your NFPs on a featured feed.
              </h5>
              <h5 style={{ fontSize: "25px", marginTop: "10px" }}>
                Lets build a true web3 social network
                🤝
              </h5>
              <h5 style={{ fontSize: "20px", marginTop: "30px" }}>
                Follow us to know when it launches.
                <Button onClick={() => {window.open("https://twitter.com/nfposts_eth")} } style={{ textTransform: 'none', color: "white" }}><TwitterIcon/></Button>
              </h5>
            </Paper>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: "left", marginTop: "50px" }}>
            <Paper
             style={{
              padding: "30px",
              borderRadius: "0px",
              backgroundColor: "#3a29c7",
              color: "white"
             }}>
              <h3 style={{ fontSize: "25px" }}>
                Be visible in the crypto community!
                <VisibilityIcon style={{ marginLeft: "10px", width: "30px", height: "30px", marginTop: "-5px" }} />
              </h3>
              <h5 style={{ fontSize: "15px", marginTop: "20px" }}>
                4000 utility NFTs will be freely claimable by our first users.
              </h5>
              <h5 style={{ fontSize: "15px", marginTop: "10px" }}>
                Holding one showcases your NFPs on a featured feed.
              </h5>
              <h5 style={{ fontSize: "15px", marginTop: "10px" }}>
                Lets build a true web3 social network
                🤝
              </h5>
              <h5 style={{ fontSize: "15px", marginTop: "30px" }}>
                Follow us to know when it launches.
                <Button onClick={() => {window.open("https://twitter.com/nfposts_eth")} } style={{ textTransform: 'none', color: "white" }}><TwitterIcon/></Button>
              </h5>
            </Paper>
          </Box>
        </Grid> */}
        {/*
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: "center", marginTop: "50px" }}>
            <Button style={{ height: "50px", width: "150px", maxWidth: "80%", textTransform: 'none', background: "linear-gradient(71deg, rgba(59,110,255,1) 0%, rgba(184,116,255,1) 100%)", borderRadius: "15px" }} variant="contained"><span style={{ fontSize: "20px", fontWeight: "500" }}>Enter App</span></Button>
          </Box>
        </Grid>
        */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <div style={{ width: "600px", maxWidth: "100%", marginLeft: "10px", marginRight: "10px", marginTop: "70px" }} >
              <div style={{ borderStyle: "solid", borderWidth: "0px", borderRadius: "30px", padding: "10px", background: "linear-gradient(133deg, rgb(153 72 255) 0%, rgb(106 0 131) 100%)" }} >
                <h5 style={{ fontSize: "25px", textAlign: "left", marginLeft: "20px", marginTop: "10px", marginBottom: "20px", color: "white" }}>
                  Roadmap
                </h5>
                <Paper variant="outlined" style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", fontSize: "18px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Collect waitlist for initial release. Over 1,000 joined! <CheckIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "0px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Release very rough basic version with just a profile and no posts. <CheckIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "0px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#00000016", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>
                    Create complete version on testnet with on-chain following, likes, reposts, and NFT posts. <CheckIcon style={{ marginLeft: "10px" }}/>
                  </div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "0px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#ffffff16", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Release fairly complete full version on Polygon. DAO NFT collection drops. Soon! <TodoIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "0px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#ffffff16", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Build a bridge that transfers all existing Post NFTs to Layer 1. <TodoIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "0px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#ffffff16", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Enable other Layer 2 rollups. <TodoIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
                <Paper variant="outlined" style={{ borderRadius: "0px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#ffffff16", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Create governance token and distribute to community. <TodoIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
                <Paper variant="outlined" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", fontSize: "18px", marginTop: "0px", padding: "0px", backgroundColor: "#ffffff16", color: "white", borderStyle: "none" }}>
                  <div style={{padding: "20px"}}>Continue to add features and improve. Start work on mobile app. Explore ideas such as decentralized moderation and the use of prediction markets. <TodoIcon style={{ marginLeft: "10px" }}/></div>
                </Paper>
              </div>
            </div>
          </Box>
          <Box sx={{ height: "300px" }} ></Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Landing;