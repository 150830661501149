import React, { useEffect, useState } from "react";
import { getNotificationsOfAddress, getNextNotificationsOfAddress, getPostData } from "../FirebaseRetrieval.js"
import { getProfileNameFromAddress } from "../ContractHelpers.js"
import { Notification } from "./Notification.js"
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';

var notificationsSet = new Set()

function NotificationsFeed({ account, provider }) {

  const [fetchedNotifications, setFetchedNotifications] = useState(false)
  const [isFetchingNextNotifications, setIsFetchingNextNotifications] = useState(false)
  const [notifications, setNotifications] = useState([]);
  const [fullPagesScrolled, setFullPagesScrolled] = useState(0)
  const [lastNotificationTimestamp, setLastNotificationTimestamp] = useState(0)

  const handleScroll = () => {
    const isMobile = (window.innerHeight / window.innerWidth) > 1
    if (isMobile) {
      const positionY = window.scrollY;
      const listHeight = window.innerHeight / 5;

      const page = parseInt(positionY/listHeight)
      if (page > fullPagesScrolled) {
        setFullPagesScrolled(page)
      }
    }
    else {
      const positionY = document.getElementById("list").scrollTop;
      const listHeight = document.getElementById("list").offsetHeight / 5;

      const page = parseInt(positionY/listHeight)
      if (page > fullPagesScrolled) {
        setFullPagesScrolled(page)
      }
    }
  };

  useEffect(() => {
    const isMobile = (window.innerHeight / window.innerWidth) > 1
    if (isMobile) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    else {
      document.getElementById("list").addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        document.getElementById("list").removeEventListener('scroll', handleScroll);
      };
    }
  }, [fullPagesScrolled]);

  async function fetchNotificationsDataFirebase(notifications, cb) {
    if (notifications.length == 1) {
      if (notifications[0].type == "follow") {
        const follower = notifications[0].follower
        getProfileNameFromAddress(follower, (followerName) => {
          var dict = {};
          dict["followerName"] = followerName
          dict["notificationType"] = notifications[0].type
          dict["notificationTimestamp"] = notifications[0].timestamp
          dict["follower"] = notifications[0].follower
          dict["notificationKey"] = notifications[0].key
          if (notificationsSet.has(notifications[0].key)) {
            cb([])
          }
          else {
            notificationsSet.add(notifications[0].key)
            cb([dict])
          }
        })
      }
      else if (notifications[0].type == "mention") {
        const tokenId = notifications[0].postTokenId
        getPostData(tokenId, (postDict) => {
          if (postDict) {
            postDict["notificationTimestamp"] = notifications[0].timestamp;
            postDict["from"] = notifications[0].from;
            postDict["notificationType"] = notifications[0].type;
            postDict["notificationKey"] = notifications[0].key
            if (notificationsSet.has(notifications[0].key)) {
              cb([])
            }
            else {
              notificationsSet.add(notifications[0].key)
              cb([postDict])
            }
          }
          else {
            cb([])
          }
        })
      }
      else if (notifications[0].type == "reply") {
        const tokenId = notifications[0].postTokenId
        getPostData(tokenId, (postDict) => {
          if (postDict) {
            postDict["notificationTimestamp"] = notifications[0].timestamp;
            postDict["from"] = notifications[0].from;
            postDict["notificationType"] = notifications[0].type;
            postDict["notificationKey"] = notifications[0].key
            if (notificationsSet.has(notifications[0].key)) {
              cb([])
            }
            else {
              notificationsSet.add(notifications[0].key)
              cb([postDict])
            }
          }
          else {
            cb([])
          }
        })
      }
    }
    else {
      // get notifications data for latest notification
      if (notifications[0].type == "follow") {
        const follower = notifications[0].follower
        getProfileNameFromAddress(follower, (followerName) => {
          var dict = {};
          dict["followerName"] = followerName
          dict["notificationType"] = notifications[0].type
          dict["notificationTimestamp"] = notifications[0].timestamp
          dict["follower"] = notifications[0].follower
          dict["notificationKey"] = notifications[0].key
          var poppedNotifications = [...notifications];
          poppedNotifications.shift();
          fetchNotificationsDataFirebase(poppedNotifications, (notificationsArr) => {
            if (!notificationsSet.has(notifications[0].key)) {
              notificationsArr.push(dict)
              notificationsSet.add(notifications[0].key)
            }
            cb(notificationsArr)
          })
        })
      }
      else if (notifications[0].type == "mention") {
        const tokenId = notifications[0].postTokenId
        getPostData(tokenId, (postDict) => {
          if (postDict) {
            postDict["notificationTimestamp"] = notifications[0].timestamp;
            postDict["from"] = notifications[0].from;
            postDict["notificationType"] = notifications[0].type;
            postDict["notificationKey"] = notifications[0].key
            var poppedNotifications = [...notifications];
            poppedNotifications.shift();
            fetchNotificationsDataFirebase(poppedNotifications, (notificationsArr) => {
              if (!notificationsSet.has(notifications[0].key)) {
                notificationsArr.push(postDict)
                notificationsSet.add(notifications[0].key)
              }
              cb(notificationsArr)
            })
          }
          else {
            var poppedNotifications = [...notifications];
            poppedNotifications.shift();
            fetchNotificationsDataFirebase(poppedNotifications, (notificationsArr) => {
              cb(notificationsArr)
            })
          }
        })
      }
      else if (notifications[0].type == "reply") {
        const tokenId = notifications[0].postTokenId
        getPostData(tokenId, (postDict) => {
          if (postDict) {
            postDict["notificationTimestamp"] = notifications[0].timestamp;
            postDict["from"] = notifications[0].from;
            postDict["notificationType"] = notifications[0].type;
            postDict["notificationKey"] = notifications[0].key
            var poppedNotifications = [...notifications];
            poppedNotifications.shift();
            fetchNotificationsDataFirebase(poppedNotifications, (notificationsArr) => {
              if (!notificationsSet.has(notifications[0].key)) {
                notificationsArr.push(postDict)
                notificationsSet.add(notifications[0].key)
              }
              cb(notificationsArr)
            })
          }
          else {
            var poppedNotifications = [...notifications];
            poppedNotifications.shift();
            fetchNotificationsDataFirebase(poppedNotifications, (notificationsArr) => {
              cb(notificationsArr)
            })
          }
        })
      }
    }
  }

  useEffect(() => {
    if (fullPagesScrolled > 0 && notifications.length > 0) {
      // add new set of post
      const earliestPostTimestamp = notifications[notifications.length-1].notificationTimestamp
      if (earliestPostTimestamp == lastNotificationTimestamp) {
        return;
      }
      setIsFetchingNextNotifications(true)
      getNextNotificationsOfAddress(account, earliestPostTimestamp, (notifications) => {
        setLastNotificationTimestamp(earliestPostTimestamp)
        if (notifications.length == 0 ) {
          setIsFetchingNextNotifications(false)
          return
        }
        fetchNotificationsDataFirebase(notifications, (notificationsArr) => {
          notificationsArr.sort((a, b) => {
            return b.notificationTimestamp - a.notificationTimestamp;
          });
          setIsFetchingNextNotifications(false)
          setNotifications(notifications => [...notifications].concat(notificationsArr));
        })
      })
    }
  }, [fullPagesScrolled]);

  useEffect(() => {
    async function fetchNotifications(account) {
      getNotificationsOfAddress(account, (notifications) => {
        if (notifications.length == 0 ) {
          setFetchedNotifications(true)
          return
        }
        fetchNotificationsDataFirebase(notifications, (notificationsArr) => {
          notificationsArr.sort((a, b) => {
            return b.notificationTimestamp - a.notificationTimestamp;
          });
          setNotifications(notificationsArr)
          setFetchedNotifications(true)
        })
      })
    }
    if (account) {
      setNotifications([])
      setFetchedNotifications(false)
      notificationsSet = new Set()
      fetchNotifications(account);
    }
  }, [account]);

  return (
    <React.Fragment>
      <div id="list" style={{marginTop: "5px", height: ((window.innerHeight / window.innerWidth) > 1) ? "auto": "90vh", overflowY: ((window.innerHeight / window.innerWidth) > 1) ? "visible" : "auto" }}>
        <Paper square elevation={0}  sx={{ pb: '50px', width: 500, maxWidth: "100%", margin: 'auto'}}>
          <h5 style={{marginBottom: "10px", marginLeft: "20px", marginTop: "10px"}}>Notifications</h5>
          <List sx={{ mb: 2, mt: 0, ml: 1, mr: 1 }}>
            {notifications.map((notification) =>
              <React.Fragment key={notification.notificationKey}>
                <Notification
                  provider={provider}
                  account={account}
                  tokenId={notification.tokenId}
                  poster={notification.poster}
                  name={notification.name}
                  content={notification.content}
                  postTimestamp={notification.postTimestamp}
                  posCacheName={"home_feed_pos"}
                  chain={notification.chain}
                  follower={notification.follower}
                  followerName={notification.followerName}
                  notificationType={notification.notificationType}
                />
              </React.Fragment>
            )}
            { isFetchingNextNotifications &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "50px" }}>
                <CircularProgress />
              </div>
            }
            <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
          </List>
        </Paper>
      </div>
    </React.Fragment>
  )

}

export default NotificationsFeed;